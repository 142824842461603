<template>
  <SimplePage class="oneclick-page">
    <div class="message-container">
      <div class="status-message -success" v-if="status === 'success'">
        <div class="status-icon -success">
          <b-icon class="svg-icon" icon="check" />
        </div>
        <p>
          <strong>Got it!</strong> We’ll allow transactions like that on your
          card in the future
        </p>
      </div>
      <div class="status-message -expired" v-else>
        <div class="status-icon -error">
          <b-icon class="svg-icon" icon="x" />
        </div>
        <p v-if="status === 'expired'">
          <strong>Uh oh&hellip;</strong> This link has expired
        </p>
        <p v-else>
          <strong>Something went wrong.</strong> If this problem persists,
          contact <a href="mailto:support@privacy.com">support@privacy.com</a>
        </p>
      </div>
      <div class="home-link">
        You can close this window or <a href="/">head home</a>
      </div>
    </div>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { BIcon, BIconCheck, BIconX } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconCheck,
    BIconX,
  },
})
export default class OneClick extends Vue {
  status = "";

  created() {
    this.status = this.$route.params.status || "";
  }
}
</script>
<style lang="scss" scoped>
.oneclick-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  min-height: 100vh;
  font-family: $font-stack-lato;

  > .privacy-logo {
    margin-bottom: auto;
  }

  .message-container {
    margin: auto 0;
    padding: 40px 10px;
    max-width: 540px;
  }

  .message-container > .status-message {
    display: flex;
    align-items: center;
    padding: 40px;
    background-color: $gray-100;
    border-radius: 20px;
    font-size: 20px;
    line-height: 1.6;
    color: $gray-800;

    @media #{$media-phone} {
      flex-direction: column;
      padding: 20px;
      border-radius: 10px;
      font-size: 16px;
      text-align: center;
    }
  }

  .message-container > .status-message strong {
    font-weight: bold;
  }

  .message-container > .status-message > .status-icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin: 0 40px 0 0;
    height: 50px;
    width: 50px;
    background-color: $red;
    border-radius: 50px;

    &.-success {
      background-color: $green;
    }

    .svg-icon {
      transform: scale(1.5);
    }

    svg * {
      fill: $white;
    }

    @media #{$media-phone} {
      margin: 0 0 20px;
    }
  }

  .message-container > .home-link {
    margin-top: 20px;
    text-align: center;
    color: $gray-600;
  }

  .message-container > .home-link > a {
    text-decoration: underline;
    color: inherit;
  }

  @media #{$media-phone} {
    min-height: auto;
  }
}
</style>
