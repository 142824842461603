export enum OnboardingSteps {
  // The order of these steps matters! It's used in the progress widget.
  BLOCKED,
  PURPOSE,
  BUSINESS_TYPE,
  BUSINESS_INFO_SUCCESS,
  INFO,
  BASIC_INFO,
  BILLING_ADDRESS,
  PHONE,
  PHONE_CONFIRM,
  VERIFY_SSN,
  SSN_LAST_FOUR,
  REVIEW_DETAILS,
  BUSINESS_DETAILS,
  BUSINESS_EXECS,
  BUSINESS_USE,
  ADD_CARD,
  ADD_CARD_REROUTED,
  ADD_BANK,
  FUNDING_TYPE_CHARGE,
  FUNDING_TYPE,
  FUNDING_TYPE_BUSINESS,
  CONFIRM_CARD,
  CONFIRM_BANK,
  SUCCESS_CARD,
  SUCCESS_CARD_BATCH,
  SUCCESS_BANK,
  LEGACY_DISCLOSURES,
  CHARGE_DISCLOSURE,
  COMMERCIAL_CHARGE_DISCLOSURE,
  FLAGGED_ACCOUNT,
  APPLICATION_COMPLETE,
  SIGNUP_COMPLETE,
  ADDITIONAL_VERIFICATION,
  EXISTING_USER_VERIFY_SSN,
  EXISTING_USER_CHARGE_DISCLOSURE,
  CHARGE_TERMS_SUCCESS,
  REMEDIATION_RETRY,
  REMEDIATION_SUCCESS,
  REMEDIATION_PENDING,
  REMEDIATION_FAILURE,
  ADD_FUNDS,
  DEPRECATED_BUSINESS_INFO,
  DEPRECATED_BUSINESS_OWNERS,
  DEPRECATED_BUSINESS_INFO_ABOUT,
  DEPRECATED_BUSINESS_INFO_LEGAL,
  BILLING,
  COMPLETE,
}

const OnboardingStepsNames = {
  // The order of these steps matters! It's used in the progress widget.
  [OnboardingSteps.BLOCKED]: "Onboarding Blocked",
  [OnboardingSteps.PURPOSE]: "Onboarding Account Purpose",
  [OnboardingSteps.BUSINESS_TYPE]: "Onboarding Business Type",
  [OnboardingSteps.BUSINESS_INFO_SUCCESS]:
    "Onboarding Business Details Success",
  [OnboardingSteps.INFO]: "Onboarding Account Manager Information",
  [OnboardingSteps.BASIC_INFO]: "Onboarding Personal Information",
  [OnboardingSteps.BILLING_ADDRESS]: "Onboarding Personal Address",
  [OnboardingSteps.PHONE]: "Onboarding Phone Number",
  [OnboardingSteps.PHONE_CONFIRM]: "Onboarding Phone Number Confirm",
  [OnboardingSteps.VERIFY_SSN]: "Onboarding Full SSN Verification",
  [OnboardingSteps.SSN_LAST_FOUR]: "Onboarding SSN Last 4",
  [OnboardingSteps.REVIEW_DETAILS]: "Onboarding Confirm Information",
  [OnboardingSteps.BUSINESS_DETAILS]: "Onboarding Business Details",
  [OnboardingSteps.BUSINESS_EXECS]: "Onboarding Business Owners",
  [OnboardingSteps.BUSINESS_USE]: "Onboarding Business Intended Use",
  [OnboardingSteps.ADD_CARD]: "Onboarding Add Debit Card",
  [OnboardingSteps.ADD_CARD_REROUTED]: "Onboarding Add Debit Card Rerouted",
  [OnboardingSteps.ADD_BANK]: "Onboarding Select Bank Account",
  [OnboardingSteps.FUNDING_TYPE_CHARGE]: "Onboarding Link Bank Account",
  [OnboardingSteps.FUNDING_TYPE]: "Onboarding Funding Type",
  [OnboardingSteps.FUNDING_TYPE_BUSINESS]: "Onboarding Funding Type Business",
  [OnboardingSteps.CONFIRM_CARD]: "Onboarding Confirm Debit Card",
  [OnboardingSteps.CONFIRM_BANK]: "Onboarding Confirm Bank",
  [OnboardingSteps.SUCCESS_CARD]: "Onboarding Add Debit Card Success",
  [OnboardingSteps.SUCCESS_CARD_BATCH]:
    "Onboarding Add Debit Card Batch Success",
  [OnboardingSteps.SUCCESS_BANK]: "Onboarding Link Bank Account Success",
  [OnboardingSteps.LEGACY_DISCLOSURES]: "Onboarding Legacy Disclosures",
  [OnboardingSteps.CHARGE_DISCLOSURE]: "Onboarding Charge Terms",
  [OnboardingSteps.COMMERCIAL_CHARGE_DISCLOSURE]:
    "Onboarding Commercial Charge Terms",
  [OnboardingSteps.FLAGGED_ACCOUNT]: "Onboarding Flagged Account",
  [OnboardingSteps.APPLICATION_COMPLETE]: "Onboarding Application Complete",
  [OnboardingSteps.SIGNUP_COMPLETE]: "Onboarding Signup Complete",
  [OnboardingSteps.ADDITIONAL_VERIFICATION]:
    "Onboarding Additional Verification",
  [OnboardingSteps.EXISTING_USER_VERIFY_SSN]:
    "Onboarding Existing User Full SSN Verification",
  [OnboardingSteps.EXISTING_USER_CHARGE_DISCLOSURE]:
    "Onboarding Existing User Charge Terms",
  [OnboardingSteps.CHARGE_TERMS_SUCCESS]: "Onboarding Charge Terms Success",
  [OnboardingSteps.REMEDIATION_RETRY]: "Onboarding Remediation Retry",
  [OnboardingSteps.REMEDIATION_SUCCESS]: "Onboarding Remediation Success",
  [OnboardingSteps.REMEDIATION_PENDING]: "Onboarding Remediation Pending",
  [OnboardingSteps.REMEDIATION_FAILURE]: "Onboarding Remediation Failure",
  [OnboardingSteps.ADD_FUNDS]: "Onboarding Add Funds",
  [OnboardingSteps.DEPRECATED_BUSINESS_INFO]:
    "Deprecated - Onboarding Business Information",
  [OnboardingSteps.DEPRECATED_BUSINESS_OWNERS]:
    "Deprecated - Onboarding Business Owners",
  [OnboardingSteps.DEPRECATED_BUSINESS_INFO_ABOUT]:
    "Deprecated - Onboarding Business Information About",
  [OnboardingSteps.DEPRECATED_BUSINESS_INFO_LEGAL]:
    "Deprecated - Onboarding Business Information Legal",
  [OnboardingSteps.BILLING]: "Onboarding Subscription Billing",
  [OnboardingSteps.COMPLETE]: "Onboarding Complete",
} as const;

export function onboardingStepName(step: OnboardingSteps) {
  return OnboardingStepsNames[step];
}

export enum ProgressSteps {
  CREATE_ACCOUNT,
  CREATE_CARD,
  COMPLETE_PROFILE,
  CONNECT_FUNDING,
  SIGN_TERMS,
  DO_TRANSACTION,
  COMPLETE,
}

export interface ProgressStepScreen {
  id: ProgressSteps;
  title: string;
  text?: string;
  actionText?: string;
}

export enum HelperSteps {
  COMPLETE_PROFILE,
  PHONE_CONFIRM,
  VERIFY_SSN,
  CONNECT_FUNDING,
  ACCEPT_TERMS,
  COMPLETE_SUBSCRIPTION,
  CONFIRM_EMAIL,
  ADDITIONAL_VERIFICATION,
  BUSINESS_REVIEW,
  CREATE_CARD,
  DO_TRANSACTION,
}

export type HelperScreens = {
  [key in HelperSteps]: {
    hideProgress?: boolean;
    title: string;
    description?: string;
    actionText?: string;
    actionFn?: () => void;
    secondaryActionText?: string;
    secondaryActionFn?: () => void;
  };
};

/* eslint-disable camelcase */
export interface GoogleMapsAddressResult {
  administrative_area_level_1: string; // State (i.e. NY)
  administrative_area_level_2: string; // County
  country: string; // (i.e. United States)
  latitude: number;
  locality: string; // City
  longitude: number;
  postal_code: string;
  route: string; // Street
  street_number: string;
}

export interface AddressResult {
  address1: string;
  zipcode?: string;
}
