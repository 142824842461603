<template>
  <div></div>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import { Component, Vue } from "vue-property-decorator";
import { userStore, subscriptionStore } from "@/store";
import { OnboardingSteps } from "@/types/Onboarding";
import { SubscriptionType } from "@/types/Subscription";

@Component
export default class BusinessVerify extends Vue {
  get user() {
    return userStore.getters.currentUser;
  }

  get verificationState() {
    if (!ldGet<any, string>(this.user, "organization.infoComplete")) {
      return `onboarding-${OnboardingSteps.DEPRECATED_BUSINESS_INFO}`;
    }
    if (!ldGet<any, string>(this.user, "organization.legalComplete")) {
      return `onboarding-${OnboardingSteps.DEPRECATED_BUSINESS_INFO_LEGAL}`;
    }
    return "";
  }

  created() {
    userStore.actions
      .getCurrentUser()
      .then(subscriptionStore.actions.fetchSubscription)
      .then(() => {
        const infoState = this.verificationState;
        const subscription = subscriptionStore.getters.subscription;

        if (
          subscription?.subscriptionType !== SubscriptionType.FREE &&
          infoState
        ) {
          this.$cookies.set("isVerifyingOrg", "true");
          this.$router.push({ name: infoState });
        } else {
          this.$router.push({ name: "home" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "home" });
      });
  }
}
</script>
