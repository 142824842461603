import { Card } from "./Card";

enum CoreFeeType {
  FX = "FX",
  CARDFUNDING = "CARDFUNDING",
}

export interface HideColumns {
  descriptor?: boolean;
  date?: boolean;
  settlement?: boolean;
  amount?: boolean;
  declineReason?: boolean;
}

export enum TransactionFilter {
  "intlOrGt500Transactions",
  "lt5Transactions",
  "approvedTransactions",
  "declinedTransactions",
  "starTransactions",
  "visaTransactions",
}

export interface TransactionLog {
  type: string;
}

export interface TransactionBalanceList {
  ach: number;
  cash: number;
  pendingAuth: number;
  promo: number;
}

export interface TransactionBatch {
  batchid: number;
  transactionid: number;
  type: number;
  created: Date;
  received: Date | null;
  bankaccountid: number;
  amount: number;
  sent: Date | null;
  result: number;
  uuid: string;
  traceid: number;
}

export interface TransactionRefund {
  uuid: string;
  date: string | Date | null;
  amount: number;
}

export class Transaction {
  accepted: boolean | number | string;
  accountName: string;
  achReceived?: Date;
  achResult?: string;
  amount: number;
  AVS: string;
  bank: string;
  bankAccountID?: string;
  bankName?: string;
  balanceList?: TransactionBalanceList;
  batches?: TransactionBatch[];
  batchAmount: number;
  batchID?: string;
  batchResult: string;
  batchSent?: string | Date;
  batchTotal: number;
  batchTries: number;
  batchTypes: number[];
  cashback?: number;
  card?: Card;
  cardID?: string;
  cardid?: string;
  cardIPAddress?: string;
  cardStyle?: any;
  city: string;
  country: string;
  calculatedFees?: Partial<Record<CoreFeeType, number>>;
  isEstimatedFee?: boolean;
  totalFees?: number;
  dateAuthorized: string | Date | null;
  dateSettled: string | Date | null;
  declineReason: string;
  declinereason?: string;
  descriptor: string;
  displayDate?: string | null;
  email?: string;
  fundingBank?: string;
  fundingAccount?: string;
  hasBounced: boolean;
  mcc: string;
  mccDescription: string;
  mccdescription?: string;
  netAmount?: number;
  network: string | number;
  PAN: string;
  promoCreditAmount?: number;
  refunds: TransactionRefund[];
  reversed: boolean;
  settledAmount: number;
  settledamount?: number;
  state?: string;
  statename?: string;
  stateID?: string;
  style?: any;
  transactionID: string;
  transactionid?: string;
  settlementStatus?: string;

  constructor(raw: Partial<Transaction>) {
    this.accepted = raw.accepted === "false" ? false : Boolean(raw.accepted);
    this.accountName = raw.accountName || "";
    this.amount = Number(raw.amount);
    this.AVS = raw.AVS || "";
    this.bank = raw.bank || raw.bankName || "";
    this.bankAccountID = raw.bankAccountID;
    this.batchAmount = Number(raw.batchAmount);
    this.batchID = raw.batchID;
    this.batchResult = raw.batchResult || "";
    this.batchSent = raw.batchSent || "";
    this.batchTotal = Number(raw.batchTotal);
    this.batchTries = Number(raw.batchTries);
    this.batchTypes = (raw.batchTypes || "")
      .toString()
      .split(",")
      .map((type: string): number => Number(type));
    this.cardID = raw.cardID || raw.cardid;
    this.cardIPAddress = raw.cardIPAddress;
    this.city = raw.city || "";
    this.country = raw.country || "";
    this.dateAuthorized = raw.dateAuthorized
      ? new Date(raw.dateAuthorized)
      : null;
    this.dateSettled = raw.dateSettled ? new Date(raw.dateSettled) : null;
    this.declineReason = raw.declineReason || raw.declinereason || "";
    this.descriptor = raw.descriptor || "";
    this.email = raw.email || "";
    this.hasBounced = raw.hasBounced || false;
    this.mcc = raw.mcc || "";
    this.mccDescription = raw.mccDescription || raw.mccdescription || "";
    this.network = raw.network || "";
    this.PAN = raw.PAN || "";
    this.promoCreditAmount = raw.promoCreditAmount;
    this.refunds = raw.refunds || [];
    this.reversed = raw.reversed || false;
    this.settledAmount = Number(raw.settledAmount || raw.settledamount);
    this.state = raw.statename || raw.state || "";
    this.stateID = raw.stateID;
    this.transactionID = raw.transactionID || raw.transactionid || "";
    this.calculatedFees = raw.calculatedFees;
    this.isEstimatedFee = raw.isEstimatedFee;
    this.totalFees = raw.totalFees;
  }
}

export interface TransactionState {
  transactions: Transaction[];
}

export interface TransactionList {
  all: Transaction[];
  declines: Transaction[];
  totalTransactions?: number;
}

export enum TransactionFilterState {
  APPROVALS = "APPROVALS",
  DECLINES = "DECLINES",
  ALL = "ALL",
}

export interface DateBucket {
  id: number;
  title: string;
  total: number;
  transactions: Transaction[];
}
