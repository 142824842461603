<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="submit"
      :class="['signup-step', { '-loading': waiting }]"
      data-test="business-details"
    >
      <ProgressTracker
        v-if="showProgressTracker"
        :currentStep="4"
        :totalSteps="5"
      ></ProgressTracker>
      <h1>Business Details</h1>
      <p class="blurb">Some basic details about your organization</p>
      <b-alert
        variant="danger"
        :show="errorMessage.length > 0"
        data-test="error-business-details"
      >
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        name="name"
        label="Legal Business Name"
        placeholder="Company, Inc."
        v-model="business.name"
        type="text"
        maxlength="128"
        :state="errors.name ? false : null"
        data-test="input-business-name"
      ></BaseInput>
      <BaseInput
        name="dba"
        label="DBA: Doing Business As (optional)"
        placeholder="Company, Inc."
        v-model="business.doingBusinessAs"
        type="text"
        maxlength="128"
        :state="errors.doingBusinessAs ? false : null"
        data-test="input-business-dba"
      ></BaseInput>
      <BaseInput
        name="website"
        label="Company Website (optional)"
        placeholder="www.website.com"
        v-model="business.website"
        type="text"
        maxlength="128"
        :state="errors.website ? false : null"
        data-test="input-business-website"
      ></BaseInput>
      <BaseInput
        name="ein"
        label="EIN or Tax ID"
        placeholder="000000000"
        v-model="business.ein"
        type="text"
        maxlength="9"
        :state="errors.ein ? false : null"
        :format="Format.NUMERIC"
        data-test="input-business-ein"
      ></BaseInput>
      <AddressInput
        @address-changed="handleAddressChanged"
        :existingAddress="business.address.street1"
        :isError="errors.address.street1"
        label="Company Address"
        placeholder="123 Street"
        data-test="input-business-street"
      />
      <BaseInputGroup>
        <BaseInput
          name="street2"
          label="Apt/Unit (optional)"
          placeholder="Apartment"
          v-model="business.address.street2"
          type="text"
          maxlength="128"
          :state="errors.address.street2 ? false : null"
          data-test="input-business-apt"
        />
        <BaseInput
          name="zipcode"
          label="ZIP Code"
          placeholder="00000"
          v-model="business.address.zipcode"
          type="text"
          maxlength="5"
          :state="errors.address.zipcode ? false : null"
          data-test="input-business-zip"
        />
      </BaseInputGroup>
      <BaseButton
        variant="primary"
        type="submit"
        size="lg"
        :loading="waiting"
        class="submit-button"
        data-test="submit-business-details"
        @click="trackClick"
      >
        Next Step
      </BaseButton>
      <OnboardingFooterActions
        :allowChangeAccountType="true"
        :showFinishLater="true"
        :hasEnteredData="hasEnteredData"
        @footer-action="onFooterAction"
      />
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { organizationStore } from "@/store";
import { Format } from "@/types/Form";
import { AddressResult, onboardingStepName, OnboardingSteps } from "@/types/Onboarding";
import ProgressTracker from "@/components/ProgressTracker.vue";
import AddressInput from "@/components/AddressInput.vue";
import OnboardingFooterActions from "@/components/OnboardingFooterActions.vue";

@Component({
  components: {
    ProgressTracker,
    AddressInput,
    OnboardingFooterActions,
  },
})
export default class BusinessDetails extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) showProgressTracker!: boolean;

  Format = Format;

  waiting = false;
  business = {
    name: "",
    doingBusinessAs: "",
    website: "",
    ein: "",
    address: {
      street1: "",
      street2: "",
      zipcode: "",
    },
  };
  errors = {
    name: false,
    doingBusinessAs: false,
    website: false,
    ein: false,
    address: {
      street1: false,
      street2: false,
      zipcode: false,
    },
  };
  errorMessage = "";

  get hasEnteredData(): boolean {
    return !!(
      this.business.name ||
      this.business.doingBusinessAs ||
      this.business.website ||
      this.business.ein ||
      this.business.address.street1 ||
      this.business.address.street2 ||
      this.business.address.zipcode
    );
  }

  handleAddressChanged(addressResult: AddressResult): void {
    // Fill in data from google.  If user needs to change it, they can.
    this.business.address.street1 = addressResult.address1;
    if (addressResult.zipcode) {
      this.business.address.zipcode = addressResult.zipcode;
    }
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BUSINESS_DETAILS)} Primary CTA`,
    });
  }

  submit(): void {
    if (this.business.name.length > 0) {
      this.errors.name = false;
    } else {
      this.errors.name = true;
      this.errorMessage = "Please provide your organization's name";
      return;
    }

    if (this.business.ein.length === 9) {
      this.errors.ein = false;
    } else {
      this.errors.ein = true;
      this.errorMessage = "Please provide your organization's EIN or Tax ID";
      return;
    }

    if (this.business.address.street1.length > 0) {
      this.errors.address.street1 = false;
    } else {
      this.errors.address.street1 = true;
      this.errorMessage = "Please provide your organization's address";
      return;
    }

    if (this.business.address.zipcode.length === 5) {
      this.errors.address.zipcode = false;
    } else {
      this.errors.address.zipcode = true;
      this.errorMessage = "Please provide your organization's ZIP code";
      return;
    }

    this.waiting = true;
    organizationStore.actions
      .details(this.business)
      .then(() => {
        this.$emit("complete-step");
      })
      .catch((err) => {
        this.errorMessage = err.response?.data?.message || "There was an error";
      })
      .finally(() => {
        this.waiting = false;
      });
  }

  onFooterAction(nextScreen: OnboardingSteps): void {
    this.$emit("complete-step", nextScreen);
  }
}
</script>

<style lang="scss" scoped>
@import "./business-info-base";

.submit-button {
  margin-top: 1rem;
}
</style>
