<template>
  <BaseForm
    class="signup-step -login"
    @submit="login"
    :class="{
      error: submitted && errorMessage.length > 0,
      loading: loginWaiting,
      '-loading': loginWaiting,
    }"
    novalidate="novalidate"
  >
    <div class="form-header">
      <h1>Log In</h1>
      <router-link
        class="header-link"
        to="/reset-password"
        data-test="reset-password"
        @click="() => $piwik.trackClick({ name: 'Reset Password' })"
      >
        Reset Password
      </router-link>
    </div>
    <div class="error-message" v-if="submitted && errorMessage.length > 0">
      {{ errorMessage }}
    </div>
    <BaseInput
      name="email"
      label="Email"
      placeholder="email@example.com"
      v-model="user.email"
      type="email"
      max-length="64"
      :autofocus="true"
      :state="submitted && !user.email ? false : null"
    />
    <BaseInput
      name="password"
      label="Password"
      placeholder="Password"
      v-model="user.password"
      type="password"
      max-length="128"
      :state="submitted && !user.password.trim() ? false : null"
    />
    <b-form-checkbox
      name="remember-email"
      id="remember-email"
      v-model="rememberEmail"
      class="my-2"
      @change="updateSavedEmail"
      v-b-tooltip.hover.bottomleft.ds300
      title="Autofill email on this device"
    >
      Remember email
    </b-form-checkbox>
    <Recaptcha
      class="captcha"
      v-if="promptCaptcha"
      :onCaptchaVerified="onCaptchaVerified"
      :onCaptchaExpired="onCaptchaExpired"
    />
    <BaseButton
      class="submit-button"
      type="submit"
      variant="primary"
      :loading="loginWaiting"
      data-test="button-login"
      @click="() => $piwik.trackClick({ name: 'Log in' })"
    >
      Log In
    </BaseButton>
    <div class="blurb">
      New to Privacy?
      <router-link
        class="header-link"
        to="/signup"
        @click="() => $piwik.trackClick({ name: 'Sign up' })"
        >Sign up here</router-link
      >.
    </div>
  </BaseForm>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { AxiosError } from "axios";
import { Extension } from "@/mixins/Extension";
import { EVENTS } from "@/types/Event";
import {
  eventStore,
  featureStore,
  loginStore,
  subscriptionStore,
  userStore,
} from "../../store";
import { validateEmail } from "../../util";
import BaseButton from "../../components/BaseButton.vue";
import BaseForm from "../../components/BaseForm.vue";
import BaseInput from "../../components/BaseInput.vue";
import Recaptcha from "../../components/Recaptcha.vue";

const REMEMBER_EMAIL_COOKIE = "rememberEmail";

@Component({
  components: {
    BaseButton,
    BaseForm,
    BaseInput,
    Recaptcha,
  },
})
export default class Login extends Mixins(Extension) {
  submitted = false;
  errorMessage = "";
  loginWaiting = false;
  user = {
    email: "",
    password: "",
    resetEmail: "",
  };
  promptCaptcha = false;
  captchaResponse: string | null = null;
  rememberEmail = false;

  mounted() {
    const rememberedEmail = this.$cookies.get(REMEMBER_EMAIL_COOKIE);

    if (rememberedEmail) {
      this.user.email = atob(rememberedEmail);
      this.rememberEmail = true;
    }
  }

  updateSavedEmail() {
    if (!this.rememberEmail) {
      this.$cookies.remove(REMEMBER_EMAIL_COOKIE);
    }
  }

  setSavedEmail() {
    this.$cookies.set(REMEMBER_EMAIL_COOKIE, btoa(this.user.email), Infinity);
  }

  async login() {
    (document.activeElement as HTMLElement).blur();
    this.loginWaiting = true;
    this.submitted = true;
    this.errorMessage = "";

    if (!this.checkLoginFormValidation()) {
      this.loginWaiting = false;
      this.captchaResponse = null;

      return;
    }

    let user: any;

    try {
      user = await userStore.actions.login({
        email: this.user.email.toLowerCase(),
        password: this.user.password,
        extensionInstalled: this.isExtensionInstalled(),
        captchaResponse: this.captchaResponse || "",
      });
    } catch (err) {
      const error = (err as AxiosError).response?.data;

      this.captchaResponse = null;
      this.loginWaiting = false;
      this.errorMessage = error?.message || "Invalid email or password";

      if (/captcha/i.test(this.errorMessage)) {
        this.promptCaptcha = true;
      }

      return;
    }

    if (this.rememberEmail) {
      this.setSavedEmail();
    }

    if (user.token) {
      try {
        await userStore.actions.getCurrentUser();
        await Promise.all([
          subscriptionStore.actions.fetchPlans(),
          subscriptionStore.actions.fetchSubscription(),
          featureStore.actions.fetchAll(),
        ]);

        loginStore.mutations.setJustLoggedIn(true);

        let redirect = "home";

        if (loginStore.getters.afterAuth?.name) {
          redirect = loginStore.getters.afterAuth.name;
          loginStore.mutations.setAfterAuth(null);
        }

        return this.$router.push({ name: redirect || "home" });
      } catch (e) {
        const err = e as Error;

        eventStore.actions.error({
          name: EVENTS.AUTH.LOGIN,
          data: {
            message: err.message,
            error: err,
          },
        });

        this.errorMessage =
          "Login successful, but there was an error fetching your account. Please refresh the page to view the dashboard.";
      }
    } else if (user.twoFactorAuth || user.oneTimeCode) {
      return this.$router.push({
        name: "tfa",
      });
    } else {
      this.errorMessage = "There was an unknown error. Please try again";
    }

    this.loginWaiting = false;
  }

  checkLoginFormValidation() {
    let state = false;

    if (!validateEmail(this.user.email)) {
      this.errorMessage = "Please enter a valid email address";
    } else if (this.user.password.trim().length === 0) {
      this.errorMessage = "Please enter a password";
    } else if (this.promptCaptcha && this.captchaResponse === null) {
      this.errorMessage = 'Please click "I\'m not a robot"';
    } else {
      state = true;
    }

    return state;
  }

  onCaptchaVerified(response: string) {
    this.captchaResponse = response;
  }

  onCaptchaExpired() {
    this.captchaResponse = null;
  }
}
</script>

<style lang="scss" scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>
