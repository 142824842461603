<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="onSubmit"
      :class="['change-password-form', 'signup-step']"
      data-test="change-password-form"
    >
      <h1>Change Your Password</h1>
      <b-alert
        v-if="errorMessage"
        variant="danger"
        show
        data-test="change-password-error"
      >
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        label="Current Password"
        placeholder="Your current password"
        type="password"
        v-model="currentPassword.value"
        maxlength="128"
        :state="currentPassword.errored ? false : null"
        :disabled="loading"
        data-test="change-password-current-password-input"
      ></BaseInput>
      <BaseInput
        label="New Password"
        placeholder="Something secure"
        type="password"
        v-model="newPassword.value"
        maxlength="128"
        :state="newPassword.errored ? false : null"
        :disabled="loading"
        data-test="change-password-new-password-input"
        :aria-label="passwordFeedback"
      >
        <PasswordStrengthMeter
          :password="newPassword.value"
          @update="updatePasswordValidity"
        />
      </BaseInput>
      <BaseButton
        type="submit"
        variant="primary"
        size="lg"
        v-if="!success"
        :disabled="loading"
        data-test="change-password-submit-button"
        @click="() => $piwik.trackClick({ name: 'Set Password' })"
      >
        Change Password
      </BaseButton>
      <BaseButton
        type="submit"
        variant="success"
        size="lg"
        v-if="success"
        :disabled="true"
        data-test="change-password-success"
      >
        Success!
        <span v-if="fullpage">&nbsp;Redirecting...</span>
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import PasswordStrengthMeter, {
  PasswordStrengthMixin,
} from "@/components/PasswordStrengthMeter.vue";
import { userStore } from "@/store";

const SUCCESS_MESSAGE_DURATION = 1000;

@Component({
  components: { PasswordStrengthMeter },
})
export default class ChangePasswordForm extends Mixins(PasswordStrengthMixin) {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop() modalId!: string;
  errorMessage = "";
  currentPassword = {
    value: "",
    errored: false,
  };
  newPassword = {
    value: "",
    errored: false,
  };
  success = false;
  loading = false;

  @Watch("currentPassword.value")
  @Watch("newPassword.value")
  resetErrorState() {
    this.errorMessage = "";
    this.currentPassword.errored = false;
    this.newPassword.errored = false;
  }

  onSubmit() {
    const user = userStore.getters.currentUser;

    if (this.success) {
      return;
    }

    if (!user) {
      this.errorMessage = "Not logged in - please log in.";
      return;
    }

    if (!this.currentPassword.value || !this.newPassword.value) {
      this.errorMessage = "Please complete the form";
      this.currentPassword.errored = !this.currentPassword.value;
      this.newPassword.errored = !this.newPassword.value;
      return;
    }

    if (this.currentPassword.value === this.newPassword.value) {
      this.errorMessage = this.passwordFeedback;
      this.newPassword.errored = true;
      return;
    }

    if (!this.passwordValid) {
      this.errorMessage = this.passwordFeedback;
      this.newPassword.errored = true;
      return;
    }

    if (this.newPassword.value.toLowerCase().includes(user.email)) {
      this.errorMessage = "Your new password can't contain your email address";
      this.newPassword.errored = true;
      return;
    }

    this.loading = true;

    const data = {
      oldPassword: this.currentPassword.value,
      newPassword: this.newPassword.value,
    };

    userStore.actions
      .changePassword(data)
      .then(() => {
        this.success = true;
        this.resetErrorState();

        if (this.fullpage) {
          setTimeout(() => {
            this.$router.push({
              name: "home",
            });
          }, SUCCESS_MESSAGE_DURATION);
        } else if (this.modalId) {
          setTimeout(() => {
            this.$bvModal.hide(this.modalId);
          }, SUCCESS_MESSAGE_DURATION);
        }
      })
      .catch((err) => {
        this.loading = false;
        const defaultMsg = "Failed to reset password";

        // wrong password
        if (err.message.includes("403")) {
          this.currentPassword.errored = true;
          this.errorMessage = "Your current password is wrong";
          return;
        }

        this.errorMessage = err?.response?.data?.message || defaultMsg;
      });
  }
}
</script>
<style lang="scss" scoped>
.signup-step.change-password-form {
  // make form wide enough to fit the h1 in one line
  width: 20rem;

  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }
  ::v-deep input.is-invalid {
    // remove icon from bootstrap input contextual state
    background-image: unset !important;
  }
}
</style>
