<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :fullpage="fullpage"
      @submit="submit"
      :class="['signup-step', { '-loading': waiting }]"
      data-test="business-execs"
    >
      <ProgressTracker
        v-if="showProgressTracker"
        :currentStep="5"
        :totalSteps="6"
      ></ProgressTracker>
      <h1>Executives &amp; Owners</h1>
      <p class="blurb">
        Tell us about the owners of your business. We are required to collect
        and verify this information to comply with regulations.
      </p>
      <b-alert
        variant="danger"
        :show="errorMessage.length > 0"
        data-test="error-business-execs"
      >
        {{ errorMessage }}
      </b-alert>
      <BaseSelect
        name="type"
        label="Please select an option:"
        placeholder="---"
        v-model="business.ownershipType"
        :options="ownershipOptions"
        :state="errors.ownershipType ? false : null"
        @input="updatedType"
        data-test="input-business-type"
      ></BaseSelect>
      <div class="owners-list" v-if="shouldShowOwners">
        <div
          class="owner-field"
          v-for="(owner, index) in business.owners"
          :key="index"
          :class="{ '-error': errors.owners[index] }"
        >
          <div class="label">Owner Details</div>
          <a
            class="side-link"
            @click="removeOwner(index)"
            :data-test="'button-owner-remove-' + index"
            >Remove</a
          >
          <BaseInputGroup>
            <BaseInput
              :name="'firstName' + index"
              placeholder="First Name"
              v-model="owner.firstName"
              type="text"
              maxlength="128"
              :data-test="'input-owner-first-name-' + index"
            />
            <BaseInput
              :name="'lastName' + index"
              placeholder="Last Name"
              v-model="owner.lastName"
              type="text"
              maxlength="128"
              :data-test="'input-owner-last-name-' + index"
            />
          </BaseInputGroup>
          <BaseInput
            :name="'address1' + index"
            placeholder="US Street Address"
            v-model="owner.address1"
            type="text"
            maxlength="128"
            :data-test="'input-owner-address1-' + index"
          />
          <BaseInputGroup>
            <BaseInput
              :name="'address2' + index"
              placeholder="APT/Unit"
              v-model="owner.address2"
              type="text"
              maxlength="128"
              :data-test="'input-owner-address2-' + index"
            />
            <BaseInput
              :name="'zipcode' + index"
              placeholder="ZIP Code"
              v-model="owner.zipcode"
              type="text"
              maxlength="5"
              :data-test="'input-owner-zipcode-' + index"
            />
          </BaseInputGroup>
          <BaseInput
            :name="'dob' + index"
            placeholder="Date of Birth (MM/DD/YYYY)"
            v-model="owner.dob"
            type="text"
            maxlength="10"
            :format="Format.DATE"
            :data-test="'input-owner-dob-' + index"
          />
          <BaseInput
            :name="'ssn' + index"
            placeholder="SSN Last 4"
            v-model="owner.ssnLast4"
            :disabled="owner.needsAlternateID"
            type="text"
            maxlength="4"
            :format="Format.NUMERIC"
            :data-test="'input-owner-ssn-' + index"
          />
          <b-form-checkbox
            class="ssn-checkbox"
            :class="{ '-empty': !owner.needsAlternateID }"
            size="md"
            :disabled="!!owner.ssnLast4"
            v-model="owner.needsAlternateID"
            :data-test="'check-ssn-alt-' + index"
          >
            This owner does not have an SSN
          </b-form-checkbox>
        </div>
        <BaseButton
          v-if="business.owners.length < 4"
          variant="outline-secondary"
          size="lg"
          block
          class="add-button"
          @click="addNewOwner"
          data-test="add-new-exec"
        >
          &plus; Add Additional Owner (25% or more)
        </BaseButton>
      </div>
      <BaseButton
        variant="primary"
        type="submit"
        size="lg"
        :loading="waiting"
        class="submit-button"
        data-test="submit-business-execs"
        @click="trackClick"
      >
        Submit
      </BaseButton>
      <OnboardingFooterActions
        :allowChangeAccountType="true"
        :showFinishLater="true"
        :hasEnteredData="hasEnteredData"
        @footer-action="onFooterAction"
      />
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { organizationStore } from "@/store";
import ProgressTracker from "@/components/ProgressTracker.vue";
import OnboardingFooterActions from "@/components/OnboardingFooterActions.vue";
import { BusinessOwner, OwnershipType } from "@/types/Organization";
import { Format } from "@/types/Form";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    ProgressTracker,
    OnboardingFooterActions,
  },
})
export default class BusinessExecs extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) showProgressTracker!: boolean;

  Format = Format;

  waiting = false;
  business: { ownershipType?: OwnershipType; owners: BusinessOwner[] } = {
    ownershipType: undefined,
    owners: [],
  };
  errors: { ownershipType: boolean; owners: boolean[] } = {
    ownershipType: false,
    owners: [],
  };
  errorMessage = "";

  ownershipOptions = [
    { text: "I own 25% or more", value: OwnershipType.PRIMARY_USER_OWNS },
    {
      text: "Someone else owns 25% or more, I will add their info",
      value: OwnershipType.OTHER_PERSON_OWNS,
    },
    { text: "No one owns 25% or more", value: OwnershipType.NO_ONE_OWNS },
  ];

  get shouldShowOwners(): boolean {
    return (
      this.business.ownershipType === OwnershipType.PRIMARY_USER_OWNS ||
      this.business.ownershipType === OwnershipType.OTHER_PERSON_OWNS
    );
  }

  get hasEnteredData(): boolean {
    return !!this.business.ownershipType;
  }

  addNewOwner(): void {
    const newOwner: BusinessOwner = {
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      zipcode: "",
      dob: "",
      ssnLast4: "",
      needsAlternateID: false,
    };

    this.business.owners.push(newOwner);
    this.errors.owners.push(false);
  }

  removeOwner(index: number): void {
    this.business.owners.splice(index, 1);
    this.errors.owners.splice(index, 1);
  }

  validateOwner(owner: BusinessOwner): string {
    if (!owner.firstName) {
      return "Please enter the owner's first name";
    }

    if (!owner.lastName) {
      return "Please enter the owner's last name";
    }

    if (!owner.address1) {
      return "Please enter the owner's address";
    }

    if (!owner.zipcode || owner.zipcode.length !== 5) {
      return "Please enter a valid ZIP code";
    }

    if (!owner.dob || owner.dob.length !== 10) {
      return "Please enter a correctly formatted date of birth (MM/DD/YYYY)";
    }

    if (
      (!owner.ssnLast4 || owner.ssnLast4.length !== 4) &&
      !owner.needsAlternateID
    ) {
      return "Please enter the last 4 digits of the owner's SSN, or indicate that the owner doesn't have an SSN";
    }

    return "";
  }

  updatedType(value: OwnershipType): void {
    if (
      value === OwnershipType.OTHER_PERSON_OWNS &&
      this.business.owners.length === 0
    ) {
      this.addNewOwner();
    }
  }

  trackClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BUSINESS_EXECS)} Primary CTA`,
    });
  }

  submit(): void {
    if (
      this.business.ownershipType &&
      Object.values(OwnershipType).includes(this.business.ownershipType)
    ) {
      this.errorMessage = "";
      this.errors.ownershipType = false;
    } else {
      this.errorMessage = "Please select a valid option";
      this.errors.ownershipType = true;
      return;
    }

    if (this.business.ownershipType === OwnershipType.OTHER_PERSON_OWNS) {
      if (this.business.owners.length >= 1) {
        this.errorMessage = "";
      } else {
        this.errorMessage =
          "You must provide information for at least one owner";
        return;
      }
    }

    if (this.business.ownershipType === OwnershipType.NO_ONE_OWNS) {
      this.errorMessage = "";
      this.business.owners = [];
    } else {
      for (let i = 0; i < this.business.owners.length; i++) {
        this.errorMessage = this.validateOwner(this.business.owners[i]);
        this.errors.owners[i] = !!this.errorMessage;
        if (this.errorMessage) {
          return;
        }
      }
    }

    this.waiting = true;
    organizationStore.actions
      .execs(this.business)
      .then(() => {
        this.$emit("complete-step");
      })
      .catch((err) => {
        this.errorMessage = err.response?.data?.message || "There was an error";
      })
      .finally(() => {
        this.waiting = false;
      });
  }

  onFooterAction(nextScreen: OnboardingSteps): void {
    this.$emit("complete-step", nextScreen);
  }
}
</script>

<style lang="scss" scoped>
@import "./business-info-base";

.owner-field {
  position: relative;
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-color: $gray-100;
  border-radius: $border-radius;
  animation: $animation-fade-in-up !important;

  &.-error {
    box-shadow: inset 0 0 0 2px fade-out($color-privacy-red, 0.5);
  }

  > .label {
    padding: 0.25rem;
    font-size: 12px;
    font-weight: bold;
    line-height: 10px;
  }

  > .side-link {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 12px;
    font-weight: bold;
    line-height: 10px;
    color: $blue;
    cursor: pointer;

    &:hover {
      color: darken($blue, 10%);
    }
  }

  .field ::v-deep .title {
    display: none;
  }

  .field ::v-deep input {
    position: static;
    padding: 0.25rem;
  }

  .field {
    padding: 0.25rem 0;
    margin: 0;
    background-color: transparent;
  }

  .ssn-checkbox {
    margin-left: 0.25rem;
    margin-top: 0.5rem;

    &.-empty ::v-deep .custom-control-label::before {
      background-color: $white;
    }
  }
}

.add-button {
  border-width: 1px;
  padding: 1rem;
}

.submit-button {
  margin-top: 1rem;
}
</style>
