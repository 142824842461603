<template>
  <div>
    <div class="section-heading">
      <h2>Discreet Merchants</h2>
    </div>
    <div class="account-private-payments section">
      <p>
        This feature gives you additional privacy in your purchasing by masking
        merchant information on your bank statements. You can choose a merchant
        name that will appear for all Privacy transactions.
      </p>
      <p v-if="disablePrivatePayments && !isIssuing">
        This is available to Pro and Premium subscribers only.
        <router-link :to="{ name: 'subscription-plan' }">
          Upgrade your plan </router-link
        >.
      </p>
      <b-select
        class="dropdown"
        :value="customDescriptor"
        :disabled="loading || disablePrivatePayments"
        :options="options"
        @change="onChangeCustomDescriptor"
        data-test="private-payments-select"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Mixins } from "vue-property-decorator";
import { BIcon, BIconPlus } from "bootstrap-vue";
import { CustomPrivatePayments, User } from "@/types/User";
import { userStore, subscriptionStore } from "@/store";
import { Toast } from "@/mixins/Toast";
import { SubscriptionType } from "@/types/Subscription";

interface PrivatePaymentOption {
  text: string;
  value: CustomPrivatePayments;
}

@Component({
  components: {
    BIcon,
    BIconPlus,
  },
})
export default class PrivatePayments extends Mixins(Toast) {
  @Prop({ required: true }) user!: User;
  loading = false;
  options: PrivatePaymentOption[] = [
    { text: "Don't mask my purchases", value: "" },
    { text: "Privacy.com", value: "Privacy.com" },
    { text: "H&H Hardware", value: "H&H Hardware" },
    { text: "Smileys Corner Store", value: "Smileys Corner Store" },
    { text: "NSA Gift Shop", value: "NSA Gift Shop" },
  ];

  get disablePrivatePayments() {
    const subscription = subscriptionStore.getters.subscription;

    return (
      subscription?.subscriptionType === SubscriptionType.FREE &&
      !this.user.legacyCardLimit
    );
  }

  get isIssuing() {
    const subscription = subscriptionStore.getters.subscription;
    const selectedPlan = subscriptionStore.getters.selectedPlan();

    return !!(
      subscription?.issuingPlan ||
      subscriptionStore.getters.isIssuingPlan(selectedPlan?.type)
    );
  }

  get customDescriptor() {
    return this.user.customDescriptor || "";
  }

  onChangeCustomDescriptor(descriptor: CustomPrivatePayments) {
    this.loading = true;
    userStore.actions
      .setCustomDescriptor({ descriptor })
      .then(() => {
        if (descriptor) {
          this.successToast("Discreet merchant preferences updated");
        } else {
          this.successToast("Discreet merchants disabled");
        }
        userStore.mutations.updateCurrentUser({
          customDescriptor: descriptor,
        });
      })
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message ||
            "Sorry! We couldn't update your discreet merchant preferences"
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>
<style lang="scss" scoped>
.account-private-payments > select:disabled {
  cursor: not-allowed;
}
</style>
