import { NavigationGuardNext, Route, RouteConfig } from "vue-router";

import { userStore } from "@/store";
import AppRedirect from "@/views/AppRedirect.vue";
import Home from "@/views/Home.vue";
import Cards from "@/views/Cards.vue";
import Transactions from "@/views/Transactions.vue";
import AccountPage from "@/views/account/AccountPage.vue";
import AccountSummary from "@/views/AccountSummary.vue";
import ReissuePage from "@/views/ReissuePage.vue";
import Refer from "@/views/Refer.vue";
import OneClick from "@/views/OneClick.vue";
// import ChargeReissuePage from "@/views/ChargeReissue.vue";
import Repayment from "@/views/Repayment.vue";
import IdVerify from "@/views/IdVerify.vue";
import OpenDispute from "@/views/OpenDispute.vue";
import MobileHelp from "@/views/MobileHelp.vue";
import { AccountPurposes, User } from "@/types/User";
import DowngradeWarning from "@/views/subscriptions/DowngradeWarning.vue";
import paths from "./paths.json";

function getUser(): Promise<User> {
  const user = userStore.getters.currentUser;

  if (user) {
    return Promise.resolve(user);
  }

  return new Promise((resolve, reject) => {
    userStore.actions
      .getCurrentUser()
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .then(() => resolve(userStore.getters.currentUser!))
      .catch(reject);
  });
}

const routes: Array<RouteConfig> = [
  {
    path: paths.base,
    redirect: "/home",
  },
  {
    path: paths.home,
    name: "home",
    component: Home,
    meta: {
      title: "Home",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.cards,
    name: "cards",
    component: Cards,
    meta: {
      title: "Cards",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.transactions,
    name: "transactions",
    component: Transactions,
    meta: {
      title: "Transactions",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.summary,
    name: "summary",
    component: AccountSummary,
    meta: {
      title: "Account Summary",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.account,
    name: "account",
    component: AccountPage,
    meta: {
      title: "Account Settings",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.reissue,
    name: "reissue",
    component: ReissuePage,
    meta: {
      title: "Reissue Cards",
      showHeader: true,
      authenticate: true,
    },
    beforeEnter(_to: Route, _from: Route, next: NavigationGuardNext): void {
      getUser()
        .then((user) => {
          if (user.accountPurpose !== AccountPurposes.BUSINESS) {
            return next();
          }
          next({ name: "home" });
        })
        .catch(() => {
          next({ name: "home" });
        });
    },
  },
  {
    path: paths.repayment,
    name: "repayment",
    component: Repayment,
    meta: {
      title: "Card Repayment",
      authenticate: true,
    },
  },
  {
    path: paths.secureUpload,
    name: "secure-upload",
    component: IdVerify,
    meta: {
      title: "Secure Upload",
      authenticate: true,
    },
  },
  {
    path: paths.appRedirect,
    name: "app-redirect",
    component: AppRedirect,
  },
  {
    path: paths.refer,
    name: "refer",
    component: Refer,
    meta: {
      title: "Refer Your Friends",
      showHeader: true,
      authenticate: true,
    },
  },
  {
    path: paths.emailOneclick,
    name: "email-oneclick",
    component: OneClick,
    meta: {
      title: "Email Action",
    },
  },
  {
    path: paths.openDispute,
    name: "open-dispute",
    component: OpenDispute,
    meta: {
      title: "Open Dispute",
      authenticate: true,
    },
  },
  {
    path: paths.downgradeWarning,
    name: "downgrade-warning",
    component: DowngradeWarning,
    meta: {
      title: "Downgrade Warning",
      authenticate: true,
      showHeader: true,
    },
  },

  {
    path: paths.mobileHelp,
    name: "mobile-help",
    component: MobileHelp,
    meta: {
      title: "Help",
      showHeader: false,
      authenticate: true,
    },
  },
  /* The card reissuing page may be needed in the future but is not currently in use.
   * Route is commented so that users can not access the page.
  {
    path: "/charge-reissue",
    name: "charge-reissue",
    component: ChargeReissuePage,
    meta: {
      title: "Reissue Cards",
      showHeader: true,
      showFooter: false,
      authenticate: true,
    },
    beforeEnter(_to: Route, _from: Route, next: NavigationGuardNext): void {
      Promise.all([
        getUser(),
        featureStore.actions.fetchFlag({
          id: FeatureFlags.COMMERCIAL_CHARGE_REISSUE,
        }),
      ])
        .then(([user, flagEnabled]) => {
          if (user.commercialChargeTermsAcceptTime && flagEnabled) {
            return next();
          }

          next({ name: "home" });
        })
        .catch(() => {
          next({ name: "home" });
        });
    },
  },
  */
];

export default routes;
