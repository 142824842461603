<template>
  <div class="banner-ofac-remediation" data-test="banner-ofac-remediation">
    <div class="wrapper">
      <div class="title">
        <SVGIcon icon="error" size="16" />
        <strong class="p-2">Attention Required</strong>
      </div>
      <div class="description">
        <span class="account-state pl-2 pr-2 me-4">Account Pending</span>
        To verify your account, we need additional information about your
        business (ie. EIN, physical address).
        <span class="p-2"
          ><a href="/signup/business-type" class="verify-link"
            >Verify Account</a
          ></span
        >
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SVGIcon from "../SVGIcon.vue";

@Component({
  components: {
    SVGIcon,
  },
})
export default class BannerOFACRemediation extends Vue {}
</script>
<style lang="scss" scoped>
.banner-ofac-remediation {
  $media-width-1200: "only screen and (max-width: 1200px)";
  $media-width-1110: "only screen and (max-width: 1110px)";
  $media-width-820: "only screen and (max-width: 820px)";

  $responsive-column-1200: 1090px;
  $responsive-column-1110: 820px;
  $responsive-column-820: 550px;

  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 30px 20px;

  .account-state {
    padding: 4px;
    background-color: $warning-10;
    font-size: 11px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1.1px;
    color: $warning-default;
    text-transform: uppercase;
    border-radius: 4px;
    margin-right: 4px;
  }

  .wrapper {
    display: flex;
    flex-flow: row nowrap;
    background: $gray-100;
    border-radius: $border-radius-lg;
    padding: 30px;
    text-align: center;
    justify-content: center;

    .description {
      color: $gray-800;
    }

    a.verify-link {
      color: $brand-purple;
    }
  }

  @media #{$media-width-1200} {
    max-width: $responsive-column-1200;
  }

  @media #{$media-width-1110} {
    max-width: $responsive-column-1110;
    padding: 20px;
  }

  @media #{$media-width-820} {
    max-width: 580px;

    .wrapper {
      flex-flow: column nowrap;
    }
  }
}
</style>
