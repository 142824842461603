<template>
  <div class="banner-whats-new" data-test="banner-whats-new">
    <div class="banner-content-container">
      <div class="text">
        <div class="title">What's New ✨</div>
        <div class="body">
          Introducing the Privacy Plus Plan for only $5/mo.
        </div>
      </div>
      <div class="cta-link">
        <b-link
          href="https://privacy.com/blog/introducing-privacy-plus-plan"
          target="_blank"
          aria-label="introducing privacy plus plan"
          >Learn More
        </b-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class BannerWhatsNew extends Vue {}
</script>
<style lang="scss" scoped>
.desktop-whats-new {
  @media #{$media-width-820} {
    display: none;
  }
}

.small-screen-whats-new {
  display: none;
  @media #{$media-width-820} {
    display: inline;
  }
  @media #{$media-phone} {
    display: none;
  }
}

.banner-whats-new {
  background: linear-gradient(to right, #2121a4, #4949f2);
  border-radius: $border-radius-lg;
  padding: 16px 24px;
  margin: 30px 0;
  position: relative;
  align-items: center;
}

.banner-content-container {
  padding: 15px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text {
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-weight: 600;
  font-family: $font-stack-graphik;
  border-right: #4949f2 1px solid;
  padding-right: 24px;
}

.body {
  padding-left: 24px;
}

.cta-link {
  font-weight: 600;
  font-family: $font-stack-graphik;
  background-color: rgb(27, 28, 38, 0.1);
  border-radius: $border-radius;
  color: white;
  padding: 8px 16px;

  a {
    color: white;
    text-decoration: none;
  }
}
</style>
