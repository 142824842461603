import axios, { AxiosResponse } from "axios";
import cloneDeep from "lodash/cloneDeep";
import { ActionHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";
import { userStore } from "./";
import {
  Organization,
  BusinessOwner,
  OwnershipType,
  BusinessType,
} from "../types/Organization";
import { AccountPurposes, User } from "../types/User";

const base = "/api/v1/organization";

type OrganizationState = Record<string, never>;

const builder = storeBuilder.module<OrganizationState>("organization", {});

type OrganizationAction<
  Payload = void,
  Type = void | AxiosResponse<any>,
> = ActionHandler<OrganizationState, RootState, any, Payload, Type>;

const info: OrganizationAction<Partial<Organization>> = (context, data) => {
  return axios.post(`${base}/info`, data).then((response) => {
    const currentOrganization =
      userStore.getters.currentUser?.organization || {};

    userStore.mutations.updateCurrentUser({
      organization: {
        ...cloneDeep(currentOrganization),
        infoComplete: true,
      } as Organization,
    });

    return response;
  });
};

const additionalInfo: OrganizationAction<Partial<Organization>> = (
  context,
  data
) => {
  return axios.post(`${base}/additionalInfo`, data).then((response) => {
    const currentOrganization =
      userStore.getters.currentUser?.organization || {};

    userStore.mutations.updateCurrentUser({
      organization: {
        ...cloneDeep(currentOrganization),
        infoAboutComplete: true,
      } as Organization,
    });

    return response;
  });
};

const verify: OrganizationAction<{
  ein: string;
  files: File[];
  statements: File[];
  legalAgreement: boolean;
}> = (context, data, orgExistsOverride = false) => {
  if (orgExistsOverride) {
    // Reusing org from previous subscription
    return axios.post(`${base}/verification`, { orgExistsOverride });
  }
  const formData = new FormData();

  data.files.forEach((file) => {
    formData.append("aoi", file);
  });

  data.statements.forEach((file) => {
    formData.append("statements", file);
  });

  formData.append("ein", data.ein);
  formData.append("legalAgreement", (data.legalAgreement || "").toString());

  return axios
    .post(`${base}/verification`, formData, {
      // setting the content-type to undefined makes the
      // browser fill in the correct content-type and boundaries
      // without it this line it will default to "application/json;charset=utf-8"
      // with no boundaries set and the upload will fail.
      headers: { "Content-Type": undefined },
    })
    .then((response) => {
      const currentOrganization =
        userStore.getters.currentUser?.organization || {};

      userStore.mutations.updateCurrentUser({
        organization: {
          ...cloneDeep(currentOrganization),
          legalComplete: true,
        } as Organization,
      });

      return response;
    });
};

const owners: OrganizationAction<{ owners: BusinessOwner[] }> = (
  context,
  { owners }
) => {
  return axios.post(`${base}/owners`, { owners });
};

const details: OrganizationAction<{
  name: string;
  doingBusinessAs?: string;
  website?: string;
  ein: string;
  address: { street1: string; street2?: string; zipcode: string };
}> = (context, data) => {
  return axios.post(`${base}/details`, data).then((response) => {
    const currentOrganization =
      userStore.getters.currentUser?.organization || {};

    userStore.mutations.updateCurrentUser({
      organization: {
        ...cloneDeep(currentOrganization),
        ...data,
        detailsComplete: true,
      } as Organization,
    });

    return response;
  });
};

const execs: OrganizationAction<{
  ownershipType?: OwnershipType;
  owners: BusinessOwner[];
}> = (context, data) => {
  return axios.post(`${base}/execs`, data).then((response) => {
    const currentOrganization =
      userStore.getters.currentUser?.organization || {};

    userStore.mutations.updateCurrentUser({
      organization: {
        ...cloneDeep(currentOrganization),
        ...data,
        ownersComplete: true,
      } as Organization,
    });

    return response;
  });
};

const useCase: OrganizationAction<{
  useCase: string;
  businessServices: string;
}> = (context, data) => {
  return axios.post(`${base}/useCase`, data).then((response) => {
    const currentOrganization =
      userStore.getters.currentUser?.organization || {};

    userStore.mutations.updateCurrentUser({
      organization: {
        ...cloneDeep(currentOrganization),
        useCase: data.useCase,
        businessServices: data.businessServices,
        useCaseComplete: true,
        businessServicesComplete: true,
      } as Organization,
    });

    return response;
  });
};

const businessType: OrganizationAction<
  {
    businessType: Organization["businessType"];
  },
  AxiosResponse<{ active: boolean }>
> = (context, data) => {
  return axios.post(`${base}/businessType`, data).then((response) => {
    const currentOrganization =
      userStore.getters.currentUser?.organization || {};

    const userUpdate = {
      accountPurpose: AccountPurposes.BUSINESS,
      organization: {
        ...cloneDeep(currentOrganization),
        businessType: data.businessType,
        typeComplete: true,
      } as Organization,
    } as User;

    // Update sole prop accounts to match the server state
    if (data.businessType === BusinessType.INDIVIDUAL) {
      userUpdate.isPendingBusinessInfo = false;
      userUpdate.organization!.active = response.data.active;
    }

    userStore.mutations.updateCurrentUser(userUpdate);

    return response;
  });
};

export const actions = {
  info: builder.dispatch(info),
  additionalInfo: builder.dispatch(additionalInfo),
  verify: builder.dispatch(verify),
  owners: builder.dispatch(owners),
  details: builder.dispatch(details),
  execs: builder.dispatch(execs),
  useCase: builder.dispatch(useCase),
  businessType: builder.dispatch(businessType),
};
