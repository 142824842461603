<template>
  <main class="shared-dashboard-cards">
    <SharedDashboardHeader
      :email="email"
      @logout="logout"
      @showExplainer="showExplainer"
    />
    <SharedSignup v-if="!currentUser" :class="{ hide: mobileMenuOpen }" />
    <div v-if="privacyExplainerOpen" class="privacy-explainer-container">
      <PrivacyExplainer @hideExplainer="hideExplainer" class="mx-auto" />
    </div>
    <div v-else class="shared-wrapper">
      <div
        class="cards-nav"
        :class="{ open: mobileMenuOpen }"
        v-if="cardList.length > 1"
      >
        <h2 class="card-nav-title mb-3">My Shared Cards</h2>
        <div class="card-nav-button">
          <button
            id="menubutton"
            @click="toggleMenu"
            aria-haspopup="true"
            aria-controls="menu"
            aria-label="Card menu"
          >
            My Shared Cards
            <BIcon v-if="!mobileMenuOpen" icon="chevron-down" />
            <BIcon v-else icon="chevron-up" />
          </button>
        </div>
        <div
          class="nav-content"
          id="menu"
          role="menu"
          aria-labelledby="menubutton"
        >
          <p class="nav-description">
            Purchases you make with these cards will be paid for by whoever
            shared the card with you (so please spend responsibly).
          </p>
          <ol class="card-list">
            <li
              v-for="card in cardList"
              :key="card.cardUuid"
              class="card-list-item"
              role="presentation"
            >
              <div
                role="menuitem"
                :aria-label="`View ${card.memo || 'Untitled'} card`"
                class="card-nav-item mb-3"
                :class="{ 'current-card': card.cardUuid === currentCardUuid }"
                @keypress.enter="selectCard(card.cardUuid)"
                @keypress.space="selectCard(card.cardUuid)"
                @click="selectCard(card.cardUuid)"
                tabindex="0"
              >
                <CardComponent :preview="true" :card="card" />
              </div>
              <div class="card-title">{{ card.memo || "Untitled" }}</div>
            </li>
          </ol>
        </div>
      </div>
      <section
        class="card-container"
        :class="{
          hide: mobileMenuOpen,
          [`theme-${theme}`]: theme,
          'bg-loaded': bgLoaded,
        }"
        :style="backgroundImageVar"
      >
        <b-spinner v-if="preloading" class="m-auto" />
        <SharedCardDetail
          class="m-auto card-details"
          v-if="currentCard"
          :card="currentCard"
          :theme="theme"
        />
        <footer class="footer">
          The Privacy card is issued by Patriot Bank, N.A., pursuant to licenses
          from Mastercard® International Incorporated and Visa U.S.A. Inc. and
          may be used everywhere Mastercard and Visa are accepted. Mastercard is
          a registered trademark of Mastercard International.
        </footer>
      </section>
    </div>
  </main>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from "vue-property-decorator";
import { BIcon, BIconChevronDown, BIconChevronUp } from "bootstrap-vue";
import { Toast } from "@/mixins/Toast";
import { CardType, RecipientCard } from "@/types/Card";
import { cardStore, sharingStore } from "@/store";
import CardComponent from "@/components/card/CardComponent.vue";
import PrivacyExplainer from "./PrivacyExplainer.vue";
import SharedDashboardHeader from "./SharedDashboardHeader.vue";
import SharedCardDetail from "./SharedCardDetail.vue";
import SharedSignup from "./SharedSignup.vue";

@Component({
  components: {
    BIcon,
    BIconChevronDown,
    BIconChevronUp,
    CardComponent,
    PrivacyExplainer,
    SharedCardDetail,
    SharedDashboardHeader,
    SharedSignup,
  },
})
export default class SharedDashboardCards extends Mixins(Toast) {
  email = "";
  preloading = false;
  cardList: RecipientCard[] = [];
  currentCardUuid: string | null = null;
  mobileMenuOpen = false;
  privacyExplainerOpen = false;
  bgLoaded = false;

  get currentCard(): RecipientCard | undefined {
    return this.cardList.find((card) => card.cardUuid === this.currentCardUuid);
  }

  get theme() {
    return this.currentCard?.theme.type.toLowerCase();
  }

  get backgroundImage() {
    return this.currentCard?.theme.backgroundImage;
  }

  get backgroundImageVar() {
    if (!this.backgroundImage) {
      return;
    }

    return `--shared-background: url(${this.backgroundImage})`;
  }

  get currentUser() {
    return !!this.$cookies.get("token");
  }

  get shouldLogout() {
    return this.$autologout.end;
  }

  @Watch("shouldLogout", { immediate: true })
  async autoLogout() {
    if (this.shouldLogout) {
      this.logout();
    }
  }

  @Watch("backgroundImage", { immediate: true })
  onBackgroundImageChange() {
    if (!this.backgroundImage) {
      this.bgLoaded = false;
      return;
    }

    const img = new Image();
    img.src = this.backgroundImage;
    img.onload = () => {
      this.bgLoaded = true;
    };
  }

  async logout() {
    try {
      await sharingStore.actions.logout();
      this.$router
        .push({
          name: "shared-login-email",
        })
        .catch(() => {}); // catch known error due to redirect
    } catch (err) {
      const error = err as any;
      this.errorToast(error?.response?.data?.message || "There was an error");
    }
  }

  toggleMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  showExplainer() {
    this.privacyExplainerOpen = true;
  }

  hideExplainer() {
    this.privacyExplainerOpen = false;
  }

  async getLockedMerchant() {
    const currentCard = this.currentCard;
    if (currentCard?.lockedMerchant) {
      // We already have it, no need to fetch it again
      return;
    }

    if (currentCard?.type === CardType.MERCHANT_LOCKED) {
      const { merchant } = await cardStore.actions.getLockedMerchant(
        currentCard.cardUuid
      );
      this.$set(currentCard, "lockedMerchant", merchant);
    }
  }

  selectCard(cardUuid: string) {
    this.currentCardUuid = cardUuid;
    this.mobileMenuOpen = false;
    this.privacyExplainerOpen = false;
    this.getLockedMerchant();
  }

  async created() {
    const linkToken = this.$route.params.linkToken;
    this.preloading = true;

    try {
      const response = await sharingStore.actions.getSharedCards();

      this.email = response.email;
      this.cardList = response.data;
      if (this.cardList.length) {
        this.currentCardUuid = this.cardList[0].cardUuid;
        this.getLockedMerchant();
      }
    } catch (err) {
      const error = err as any;
      const status = error.response.status;

      if (status === 401 || status === 403) {
        if (status === 403) {
          this.errorToast(error.response.data.message);
        }

        const routeName = linkToken
          ? "shared-login-token"
          : "shared-login-email";

        return this.$router.push({
          name: routeName,
          params: { linkToken },
        });
      }

      this.errorToast(error.response.data.message || "There was an error");
    } finally {
      this.preloading = false;
    }
  }

  mounted() {
    this.$autologout.start();
  }

  beforeDestroy() {
    this.$autologout.stop();
  }
}
</script>

<style lang="scss" scoped>
$focus: fade-out($blue, 0.2);

.shared-dashboard-cards {
  min-height: 100vh;
  background-color: $foreground-border;
  display: flex;
  flex-flow: column nowrap;
}

.shared-wrapper {
  display: flex;
  flex-grow: 1;
}

.cards-nav {
  padding: 40px;
  overflow: auto;
  width: 304px;
  height: calc(100vh - 88px);
  background: $white;
  flex: none;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}

.card-nav-title {
  font-family: $font-stack-fk-grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.16px;
}

.card-nav-button {
  display: none;
  border-bottom: 1px solid $neutrals-2;

  button {
    width: 100%;
    background-color: $white;
    border: none;
    height: 40px;
  }
}

.nav-description {
  font-family: $font-stack-graphik;
  font-size: 14px;
  line-height: 130%;
  color: $foreground-subtle;
  margin-bottom: 30px;
}

.card-list {
  list-style: none;
  padding: 0;
  max-width: 224px;
  margin: 0 auto;
}

.card-list-item {
  margin-bottom: 30px;
}

.card-title {
  font-family: $font-stack-graphik;
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
}

.card-nav-item {
  outline: 4px solid transparent;
  cursor: pointer !important;
  display: block;
  border-radius: $radius-large;

  &:focus:not(.current-card) {
    outline-color: $focus;
  }

  ::v-deep {
    .card-component {
      width: 100%;
    }

    .preview .card-contents {
      cursor: inherit;
    }
  }
}

.current-card {
  outline-color: rgba(73, 73, 242, 0.4);
}

.card-container {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  transition: background-color 0.2s linear;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0;
    background-repeat: repeat-x;
    background-position: top center;
    transition: opacity 0.2s linear;
    background-image: var(--shared-background);
  }

  &.bg-loaded::before {
    opacity: 1;
  }
}

.theme-confetti {
  background-color: $brand-extended-purple-5;
}

.theme-privacy {
  background-color: $brand-lime;
}

.privacy-explainer-container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.footer {
  color: $foreground-subtle;
  font-family: $font-stack-graphik;
  font-size: 12px;
  line-height: 130%;
  padding: 24px 40px;
}

@media #{$media-phone} {
  .card-container::before {
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .card-details {
    position: relative;
  }

  .shared-wrapper {
    flex-direction: column;

    .signup:not(.hide) + & {
      padding-bottom: 250px;
    }
  }

  .cards-nav {
    width: 100%;
    height: auto;
    padding: 0;

    .nav-content {
      display: none;
      padding: 24px 40px 0;
    }

    &.open {
      flex-grow: 1;

      .nav-content {
        display: block;
      }
    }
  }

  .nav-description {
    text-align: center;
  }

  .card-nav-title {
    display: none;
  }

  .card-nav-button {
    display: block;

    button:focus {
      outline: 1px solid $focus;
    }
  }

  .hide {
    display: none !important;
  }
}
</style>
