export enum FeatureFlags {
  COMMERCIAL_CHARGE_REISSUE = "commercial-charge-reissue",
  DIGITAL_WALLET = "digital-wallet",
  NOTIFICATION_BANNER = "notification-banner",
  DURBIN_EXEMPT_BINS = "durbin-exempt-bins",
  CARD_PAGE_UPDATES_V1 = "card-page-updates-v1",
  REFRESH_BANK_TOKEN = "refresh-bank-token",
  DISPLAY_API_WIDGET = "display-api-widget",
  DISPLAY_WHATS_NEW = "display-whats-new-banner",
  NEW_DISCREET_MERCHANTS = "new-discreet-merchants",
}
