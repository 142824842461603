<template>
  <div data-test="account-page">
    <div class="account-page" v-if="loading">
      <b-spinner class="spinner" label="Loading..." v-if="loading"></b-spinner>
    </div>
    <div class="account-page" v-if="!loading">
      <div class="main-section">
        <div class="section-wrapper">
          <ProfileCard :user="user" />
        </div>
        <div class="section-wrapper">
          <AccountSettings />
        </div>
        <div class="section-wrapper">
          <AccountAuth />
        </div>
        <div class="section-wrapper" v-if="!isPendingManualPaymentsApproval">
          <Funding :showNewDiscreetMerchants="showNewDiscreetMerchants" />
        </div>
        <div class="section-wrapper">
          <AccountPlan />
        </div>
        <div class="section-wrapper">
          <AccountSpendLimits :user="user" />
        </div>
        <div class="section-wrapper">
          <AccountStatements :user="user" />
        </div>
        <div class="section-wrapper has-text-dropdown">
          <Notifications :user="user" />
        </div>
        <div
          class="section-wrapper has-text-dropdown"
          v-if="!showNewDiscreetMerchants"
        >
          <PrivatePayments :user="user" />
        </div>
        <div class="section-wrapper">
          <AccountIntegrations :user="user" />
        </div>
        <div class="section-wrapper">
          <Cashback :user="user" />
        </div>
        <div class="section-wrapper">
          <Rewards :user="user" />
        </div>
        <div class="section-wrapper" v-if="displayApiWidget">
          <AccountAPI :user="user" />
        </div>
        <div class="section-wrapper">
          <BaseButton
            variant="secondary"
            class="close-acct-btn"
            @click="showCloseAccountModal"
            data-test="close-account-button"
          >
            Close My Account
          </BaseButton>
          <CloseAccount />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { subscriptionStore, userStore, featureStore } from "@/store";
import { FeatureFlags } from "@/types/LaunchDarkly";
import ProfileCard from "@/views/account/ProfileCard.vue";
import Rewards from "@/views/account/Rewards.vue";
import AccountPlan from "@/views/account/Plan.vue";
import AccountSettings from "@/views/account/AccountSettings.vue";
import AccountAuth from "@/views/account/AccountAuth.vue";
import AccountSpendLimits from "@/views/account/AccountSpendLimits.vue";
import Cashback from "@/views/account/Cashback.vue";
import Funding from "@/views/account/Funding.vue";
import AccountAPI from "@/views/account/AccountAPI.vue";
import AccountIntegrations from "@/views/account/AccountIntegrations.vue";
import AccountStatements from "@/views/account/AccountStatements.vue";
import Notifications from "@/views/account/Notifications.vue";
import PrivatePayments from "@/views/account/PrivatePayments.vue";
import BaseButton from "@/components/BaseButton.vue";
import CloseAccount from "@/components/modals/CloseAccount.vue";
import { Confirm } from "@/mixins/Confirm";
import { Toast } from "@/mixins/Toast";
import { AxiosError } from "axios";

@Component({
  components: {
    AccountPlan,
    ProfileCard,
    Rewards,
    AccountAuth,
    AccountSettings,
    AccountSpendLimits,
    Cashback,
    Funding,
    AccountAPI,
    AccountIntegrations,
    AccountStatements,
    Notifications,
    PrivatePayments,
    BaseButton,
    CloseAccount,
  },
})
export default class AccountPage extends Mixins(Confirm, Toast) {
  loading = true;

  get displayApiWidget() {
    return (
      this.user?.api.enabled ||
      featureStore.getters.flag(FeatureFlags.DISPLAY_API_WIDGET)
    );
  }

  get showNewDiscreetMerchants() {
    return (
      !!featureStore.getters.flag(FeatureFlags.NEW_DISCREET_MERCHANTS) ||
      !!userStore.getters.accountInfo?.fundingCardBatchStatus
    );
  }

  get user() {
    return userStore.getters.currentUser;
  }

  async created() {
    try {
      await Promise.all([
        userStore.actions.getCurrentUser(),
        subscriptionStore.actions.fetchPlans(),
        userStore.actions.getAccountInfo(),
      ]);
    } catch (error) {
      if ((error as AxiosError)?.response?.status !== 401) {
        this.errorToast("Error loading Account page.");
        this.$router.push({ name: "home" });
      }
    } finally {
      this.loading = false;
    }
  }

  showCloseAccountModal() {
    this.$bvModal.show("close-account");
  }

  get isPendingManualPaymentsApproval() {
    return userStore.getters.isPendingManualPaymentsApproval;
  }
}
</script>
<style lang="scss" scoped>
.account-page {
  padding: 100px 20px 60px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.account-page > .main-section {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex-basis: 600px;
}

.account-page ::v-deep .section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  & h2 {
    font-family: $font-stack-wes-fy;
    font-size: 20px;
    margin-bottom: 0;
  }
}

.account-page .section-wrapper {
  margin-bottom: 50px;
}

.account-page .section-wrapper-padded-top {
  margin-bottom: 50px;
  margin-top: 50px;
}

.account-page ::v-deep .section {
  padding: 30px 40px;
  background-color: $gray-100;
  border-radius: $border-radius;
  color: $gray-600;
}

.account-page .has-text-dropdown ::v-deep .dropdown {
  display: block;
  margin: 25px 0;
  padding: 10px 20px;
  height: 50px;
  width: 100%;
  background-color: $white;
  box-shadow: $box-shadow-small;
  border: none;
  border-radius: $radius-default;
  font-size: 14px;
  color: $gray-900;
  transition: box-shadow $duration-short;
  font-weight: bold;
}

.account-page .close-acct-btn {
  width: 100%;
}
</style>
