import Vue from "vue";
import { Toast } from "@/mixins/Toast";
import { DirectiveBinding } from "vue/types/options";

const ZERO = 0;
const toast = new Toast();

function copyInput(el: HTMLInputElement) {
  // select all the text in the element
  el.setSelectionRange(ZERO, el.value.length);

  return new Promise((resolve, reject) => {
    // attempt copy to clipboard
    try {
      document.execCommand("copy");
      // clear selection
      el.setSelectionRange(ZERO, ZERO);
      resolve("");
    } catch (e) {
      reject();
    } finally {
      el.blur();
    }
  });
}

function copyText(value: string) {
  if (!value.length) {
    return Promise.reject("Value is required for copying");
  }

  return navigator.clipboard.writeText(value);
}

Vue.directive("clickToCopy", {
  inserted: (element: HTMLElement, { value, modifiers }: DirectiveBinding) => {
    let el: HTMLElement = element;

    // If the directive isn't assinged a value we'll assume it's an input
    // and follow the older behavior
    if (!value) {
      // If this directive isn't applied to an <input> element,
      // search for a child input element
      if (el.tagName.toUpperCase() !== "INPUT") {
        el = el.querySelector("input") as HTMLInputElement;
      }

      if (!el) {
        return;
      }
    }

    // On click, select the input text and copy to clipboard
    el.addEventListener("click", (e) => {
      if (modifiers.stop) {
        e.stopPropagation();
      }

      const copyFn = value
        ? copyText(value)
        : copyInput(el as HTMLInputElement);

      copyFn
        .then(() => {
          const successText =
            element.getAttribute("click-to-copy-text") || "Copied to clipboard";

          toast.successToast(successText);
        })
        .catch((err) => toast.errorToast(err || "Unable to copy"));
    });
  },
});
