import axios, { AxiosResponse } from "axios";
import {
  Card,
  CardState,
  CardList,
  CardTime,
  CardType,
  ChargeReissue,
  CardsPageState,
  CardSortField,
  CardSortOrder,
} from "@/types/Card";
import { BillingCategory } from "@/types/Billing";
import { ActionHandler, MutationHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";
import { cardStore, subscriptionStore, tagStore, userStore } from ".";

type CardStoreState = {
  cached: { [key: string]: Card };
  lists: { [key: string]: CardList };
  chargeReissue: ChargeReissue;
  cardsPageState: CardsPageState;
  totalUserCards: number | null;
};

const defaultSampleCard = {
  memo: "My First Card",
  type: CardType.MERCHANT_LOCKED,
  reloadable: true,
};

const CardPageDefaults = {
  CARDS_STATE: CardState.OPEN,
  CARDS_PAGE_LIMIT: 16,
  CARDS_SORT_FIELD: CardSortField.RECENTLY_USED,
  CARDS_SORT_ORDER: CardSortOrder.DESC,
};

const builder = storeBuilder.module<CardStoreState>("card", {
  cached: {},
  lists: {},
  chargeReissue: {
    expiring: [],
    reissued: {},
    closed: [],
  },
  cardsPageState: {
    currentVisibleCards: [],
    totalCards: 0,
  },
  totalUserCards: null,
});

type CardMutation<Payload = void> = MutationHandler<CardStoreState, Payload>;
type CardAction<Payload = void, Type = void> = ActionHandler<
  CardStoreState,
  RootState,
  any,
  Payload,
  Type
>;

const base = "/api/v1/card";
const baseV2 = "/api/v2/cards";

const getQueryString = ({
  limit = 0,
  offset = 0,
  query = "",
  onlyReissuable = false,
  cardsState = "",
  sortField = "",
  sortOrder = "",
  tagList = [] as string[],
}) => {
  let queryString = `?limit=${limit || ""}&offset=${offset || 0}`;
  if (cardsState !== "") {
    queryString += `&cardState=${cardsState}`;
  }
  if (sortField !== "") {
    queryString += `&sortField=${sortField}`;
  }
  if (sortOrder !== "") {
    queryString += `&sortOrder=${sortOrder}`;
  }
  if (tagList.length > 0) {
    queryString += `&tagList=${tagList.join(",")}`;
  }
  if (query) {
    queryString += `&q=${query}`;
  }
  if (onlyReissuable) {
    queryString += `&onlyReissuable=${onlyReissuable}`;
  }
  return queryString;
};

const getCard = builder.read(
  (state) => (id: string | number) => {
    return state.cached[id];
  },
  "getCard"
);

const cardList = builder.read(
  (state) =>
    (queryObj = {}): CardList => {
      const queryString = getQueryString(queryObj);

      const list = state.lists[queryString];
      if (list) {
        return list;
      }

      return {
        all: [],
        open: [],
        closed: [],
      };
    },
  "cardList"
);

const currentCardsPageState = builder.read((state) => {
  return state.cardsPageState;
}, "currentCardsPageState");

const chargeReissue = builder.read(({ chargeReissue }): ChargeReissue => {
  return chargeReissue;
}, "chargeReissue");

const getTotalUserCards = builder.read(({ totalUserCards }): number | null => {
  return totalUserCards;
}, "totalUserCards");

const hasCategoryLockedCards = builder.read(({ cached }): boolean => {
  return Object.values(cached).some(
    (card) => card.type === CardType.UNLOCKED && card.merchantCategory
  );
}, "hasCategoryLockedCards");

export const getters = {
  get cardList() {
    return cardList();
  },
  get getCard() {
    return getCard();
  },
  get chargeReissue() {
    return chargeReissue();
  },
  get currentCardsPageState() {
    return currentCardsPageState();
  },
  get defaultCardsState() {
    return CardPageDefaults.CARDS_STATE;
  },
  get defaultCardsPageLimit() {
    return CardPageDefaults.CARDS_PAGE_LIMIT;
  },
  get defaultSortField() {
    return CardPageDefaults.CARDS_SORT_FIELD;
  },
  get defaultSortOrder() {
    return CardPageDefaults.CARDS_SORT_ORDER;
  },
  get totalUserCards() {
    return getTotalUserCards();
  },
  get hasCategoryLockedCards() {
    return hasCategoryLockedCards();
  },
};

const setCard: CardMutation<Partial<Card>> = (state, card) => {
  if (card.cardID) {
    state.cached = {
      ...state.cached,
      [card.cardID]: {
        ...(state.cached[card.cardID] || {}),
        ...card,
      },
    };
  }
};

const setList: CardMutation<{ key: string; cardList: CardList }> = (
  state,
  { key, cardList }
) => {
  state.lists = { ...state.lists, [key]: cardList };
  const newCached = { ...state.cached };
  for (const card of cardList.all) {
    if (!newCached[card.cardID]) newCached[card.cardID] = card;
  }
  state.cached = newCached;
};

const setChargeReissue: CardMutation<ChargeReissue> = (state, data) => {
  state.chargeReissue = data;
};

const setExpiringCard: CardMutation<Partial<Card>> = (state, card) => {
  if (card.cardID) {
    const idx = state.chargeReissue.expiring.findIndex(
      (c: Card) => c.cardID === card.cardID
    );
    if (idx > -1) {
      const replacedCard = state.chargeReissue.expiring[idx];
      state.chargeReissue.expiring.splice(idx, 1, { ...replacedCard, ...card });
    }
  }
};

const removeExpiring: CardMutation<number> = (state, cardID) => {
  const idx = state.chargeReissue.expiring.findIndex((c) => {
    return c.cardID === cardID;
  });
  if (idx > -1) {
    state.chargeReissue.expiring.splice(idx, 1);
  }
};

const setReissuedCard: CardMutation<Partial<Card>> = (state, card) => {
  if (card.cardID) {
    const replacedCard = state.chargeReissue.reissued[card.cardID] || {};
    state.chargeReissue.reissued[card.cardID] = {
      ...replacedCard,
      ...card,
    };
  }
};

const updateCardsPageState: CardMutation<Partial<CardsPageState>> = (
  state,
  cardsPageState
) => {
  state.cardsPageState = {
    ...state.cardsPageState,
    ...cardsPageState,
  };
};

const updateTotalUserCards: CardMutation<number> = (state, totalUserCards) => {
  state.totalUserCards = totalUserCards;
};

export const mutations = {
  setCard: builder.commit(setCard),
  setList: builder.commit(setList),
  setChargeReissue: builder.commit(setChargeReissue),
  setExpiringCard: builder.commit(setExpiringCard),
  removeExpiring: builder.commit(removeExpiring),
  setReissuedCard: builder.commit(setReissuedCard),
  updateCardsPageState: builder.commit(updateCardsPageState),
  updateTotalUserCards: builder.commit(updateTotalUserCards),
};

const getMccIcon: CardAction<string> = (context, iconURI) => {
  return axios.get(
    `https://s3.amazonaws.com/privacy-web/images/streamline/${iconURI}`
  );
};

function configureCardData(card: Card) {
  // Make spendlimit property = '' if $0 so input placeholder shows
  // It comes back as a string and javascripts number system is borked so checking both
  // string or float value
  if (typeof card.spendLimit === "string") {
    card.spendLimit = card.spendLimit.replace(",", "");
  }
  if (card.spendLimit === "0.00" || Number(card.spendLimit) === 0) {
    card.spendLimit = "";
  } else {
    // This needs to be a float, otherwise the input[type=number] really upsets
    // Angular's ngModel being a string of 'X.YY'
    card.spendLimit = Number(card.spendLimit);
  }

  // If we have don't memo, but a merchant hostname, use that for the memo
  if (card.memo.length === 0 && card.hostname.length > 0) {
    card.memo = card.hostname.replace(/^www\./i, "");
  }

  // If no spendLimit set, default to transaction
  if (card.spendLimitDuration.length === 0) {
    card.spendLimitDuration = CardTime.TRANSACTION;
  }

  if (
    card.style &&
    card.style.bgColor &&
    card.style.bgColor.toLowerCase() === "ffffff"
  ) {
    delete card.style.bgColor;
  }

  if (!card.cardUuid) {
    card.cardUuid = card.uuid!;
  }

  return card;
}

const getCards: CardAction<
  {
    limit?: number;
    offset?: number;
    query?: string;
    onlyReissuable?: boolean;
    skipCache?: boolean;
    cardsState?: CardState;
    sortField?: CardSortField;
    sortOrder?: CardSortOrder;
    tagList?: string[];
  },
  CardList
> = ({ state }, queryObj = {}) => {
  const skipCache = queryObj.skipCache;
  delete queryObj.skipCache;
  const queryString = getQueryString(queryObj);

  if (state.lists[queryString] && !skipCache) {
    return Promise.resolve(state.lists[queryString]);
  }

  let urlBase = baseV2;
  let dataKey = "data";
  let totalKey = "total";
  // use V1 for certain queries, until core is able to support them directly
  if (queryObj.onlyReissuable || queryObj.query) {
    urlBase = base;
    dataKey = "cardList";
    totalKey = "totalCards";
  }

  return axios.get(urlBase + "/" + queryString).then(({ data }) => {
    const unusedCards: Card[] = [];
    const cardList: CardList = { all: [], open: [], closed: [] };

    (data[dataKey] || []).map(configureCardData).forEach((card: Card) => {
      cardList.all.push(card);

      if (card.state === CardState.CLOSED) {
        cardList.closed.push(card);
      } else if (card.unused) {
        unusedCards.push(card);
      } else {
        cardList.open.push(card);
      }
    });

    // Prepend unused cards to open card list
    cardList.open = unusedCards.concat(cardList.open);

    if (data[totalKey]) {
      cardList.totalCards = parseInt(data[totalKey]);
    }
    mutations.setList({ key: queryString, cardList });
    if (!queryObj.query) {
      mutations.updateCardsPageState({
        totalCards: data.total,
        currentVisibleCards: cardList.all,
      });
    }

    return cardList;
  });
};

const getChargeReissueCards: CardAction<void, ChargeReissue> = () => {
  return axios
    .get(base + "/charge-reissue")
    .then(({ data }) => {
      mutations.setChargeReissue(data);
      return data;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        mutations.setChargeReissue({
          expiring: [],
          reissued: {},
          closed: [],
        });
      } else {
        throw err;
      }
    });
};

const getSuggestedMerchants: CardAction<any, any[]> = () => {
  return axios
    .get(base + "/merchant/suggested")
    .then(({ data }) => data.merchants);
};

const getMerchantCategories: CardAction<void, BillingCategory[]> = () => {
  return axios.get(base + "/merchant/categories").then(({ data }) => data);
};

const searchMerchants: CardAction<string, any> = (_x, term) => {
  return axios
    .get(base + "/merchant/search", { params: { term } })
    .then(({ data }) => data.merchants);
};

const wrapper = async (
  state: CardStoreState,
  url: string,
  card: Partial<Card>,
  extraMutation?: (card: Partial<Card>) => void
): Promise<AxiosResponse<any> | void> => {
  if (!card.cardID) return;
  const oldCard = { ...state.cached[card.cardID] };
  mutations.setCard(card);
  try {
    const resp = await axios.post(url, card);
    if (resp.data.card) {
      mutations.setCard(resp.data.card);
    }
    if (extraMutation) {
      // some actions with extra mutations don't return a card
      // so default to the card passed in
      const mutationCard = resp.data.card || card;
      extraMutation(mutationCard);
    }
    return resp;
  } catch (err) {
    mutations.setCard(oldCard);
    throw err;
  }
};

type updatePayload = {
  uuid: string;
  updates: Partial<Card>;
};
const update: CardAction<updatePayload, AxiosResponse | void> = async (
  _ctx,
  { uuid, updates }
) => {
  return axios.patch(`${baseV2}/${uuid}`, updates).then((resp) => {
    mutations.setCard(resp.data);
    return resp;
  });
};

const setCardNote: CardAction<Card, Card> = async (_context, card) => {
  return axios
    .put(`${baseV2}/${card.cardUuid}/note`, { note: card.note })
    .then((resp) => {
      mutations.setCard(resp.data);
      return resp.data;
    });
};

const createSampleCard: CardAction<void, AxiosResponse> = () => {
  return axios
    .put("/api/v1/user/samplecards", { sampleCard: defaultSampleCard })
    .then((resp) => {
      mutations.setCard(defaultSampleCard);
      userStore.mutations.updateCurrentUser({ sampleCard: defaultSampleCard });
      return resp;
    });
};

const pause: CardAction<number, AxiosResponse | void> = (
  _context,
  cardUuid
) => {
  return axios
    .patch(`${baseV2}/${cardUuid}/state`, {
      state: CardState.PAUSED,
    })
    .then((resp) => {
      mutations.setCard(resp.data);
      return resp.data;
    });
};

const unpause: CardAction<number, AxiosResponse | void> = (
  _context,
  cardUuid
) => {
  return axios
    .patch(`${baseV2}/${cardUuid}/state`, {
      state: CardState.OPEN,
    })
    .then((resp) => {
      mutations.setCard(resp.data);
      return resp.data;
    });
};

const close: CardAction<number, AxiosResponse | void> = ({ state }, cardID) => {
  const removeFromExpiring = (card: Partial<Card>) => {
    if (card.cardID) {
      mutations.removeExpiring(card.cardID);
    }
  };
  return wrapper(
    state,
    base + "/" + cardID + "/close",
    {
      cardID,
      state: CardState.CLOSED,
    },
    removeFromExpiring
  );
};

const batchClose: CardAction<
  number[],
  AxiosResponse<{ expiring: Card[]; reissued: Record<number, Card> }>
> = (context, cards) => {
  return axios
    .post(`${base}/batch-close`, {
      cards,
    })
    .then((resp) => {
      if (resp.data.length >= 1) {
        resp.data.forEach((card: AxiosResponse["data"]) => {
          mutations.removeExpiring(card.body.expiringCardID);
        });
      }
      return resp;
    });
};

const fetchTotalUserCards: CardAction<void, number> = async () => {
  const resp = await axios.get(`${baseV2}/?limit=1&offset=0`);
  mutations.updateTotalUserCards(resp.data.total);
  return resp.data.total;
};

const create: CardAction<
  Partial<Card>,
  AxiosResponse<{ accountState: string; card: Card }>
> = async (_context, cardData) => {
  const user = userStore.getters.currentUser;
  const userActive =
    (user?.chargeTermsAcceptTime || user?.commercialChargeTermsAcceptTime) &&
    !user?.verificationNeeded &&
    !user?.isPendingBusinessReview;

  const fetchedTotalUserCards = await cardStore.actions.fetchTotalUserCards();
  if (fetchedTotalUserCards === 0 && user?.sampleCard && userActive) {
    await cardStore.actions.activateSampleCard();
  }

  // These aren't needed for the v2 endpoint
  delete cardData.unused;
  delete cardData.reloadable;
  delete cardData.style;

  const response = await axios.post(baseV2, cardData);

  const newCard = configureCardData(response.data);
  const cardList = getters.cardList();

  cardList.all.unshift(newCard);
  cardList.open.unshift(newCard);

  mutations.setList({ key: getQueryString({}), cardList });
  subscriptionStore.mutations.addSubscriptionCardCount(1);

  if (newCard.type === CardType.DIGITAL_WALLET) {
    tagStore.actions.fetchTags(true);
  }

  return response;
};

const batchCreate: CardAction<
  Partial<Card>[],
  { cards: Card[]; error?: string }
> = (_context, cards) => {
  const cardList = getters.cardList();

  return axios
    .post(base + "/batch", {
      cards,
    })
    .then(({ data }) => {
      (data?.cards || []).map(configureCardData).forEach((card: Card) => {
        subscriptionStore.mutations.addSubscriptionCardCount(1);

        cardList.all.unshift(card);
        cardList.open.unshift(card);
      });

      mutations.setList({ key: getQueryString({}), cardList });

      return data;
    });
};

const activateSampleCard: CardAction<void, CardList> = () => {
  const cardList = getters.cardList();

  return axios.post(base + "/activateSampleCard").then(({ data }) => {
    (data?.cards || []).map(configureCardData).forEach((card: Card) => {
      subscriptionStore.mutations.addSubscriptionCardCount(1);
      cardList.all.unshift(card);
      cardList.open.unshift(card);
    });

    mutations.setList({ key: getQueryString({}), cardList });

    return cardList;
  });
};

const getBillingLink: CardAction<number, Record<string, any>> = (
  _context,
  id
) => {
  return axios.get(base + "/billing/" + id).then(({ data }) => {
    return data;
  });
};

const reissue: CardAction<number, AxiosResponse<Card>> = (context, cardID) => {
  return axios.post(`${base}/reissue`, {
    cardID,
  });
};

const reissueCommercialCharge: CardAction<
  number,
  AxiosResponse<{ expiring: Card[]; reissued: Record<number, Card> }>
> = (context, cardID) => {
  return axios
    .post(`${base}/charge-reissue`, {
      cardID,
    })
    .then((resp) => {
      mutations.setCard(resp.data.reissued);
      mutations.setReissuedCard(resp.data.reissued);
      mutations.setExpiringCard(resp.data.expiring);
      return resp;
    });
};

const batchReissueCommercialCharge: CardAction<
  number[],
  AxiosResponse<{ expiring: Card[]; reissued: Record<number, Card> }>
> = (context, cards) => {
  return axios
    .post(`${base}/batch-reissue`, {
      cards,
    })
    .then((resp) => {
      resp.data.forEach((card: AxiosResponse["data"]) => {
        mutations.setCard(card.body.reissued);
        mutations.setReissuedCard(card.body.reissued);
        mutations.setExpiringCard(card.body.expiring);
      });
      return resp;
    });
};

const queueCommercialChargeReissue: CardAction<
  void,
  AxiosResponse<{ expiring: Card[]; reissued: Record<number, Card> }>
> = () => {
  return axios.post(`${base}/queue-commercial-charge-reissue`);
};

const exportCommercialCharge: CardAction<number[], AxiosResponse> = (
  context,
  cards
) => {
  return axios
    .post(`${base}/charge-export`, {
      cards,
    })
    .then((resp) => {
      return resp;
    });
};

const getLockedMerchant: CardAction<string, Record<string, any>> = (
  _context,
  uuid
) => {
  return axios.get(`${baseV2}/${uuid}/merchant`).then(({ data }) => {
    return data;
  });
};

export const actions = {
  getMccIcon: builder.dispatch(getMccIcon),
  getCards: builder.dispatch(getCards),
  getChargeReissueCards: builder.dispatch(getChargeReissueCards),
  update: builder.dispatch(update),
  createSampleCard: builder.dispatch(createSampleCard),
  pause: builder.dispatch(pause),
  unpause: builder.dispatch(unpause),
  close: builder.dispatch(close),
  batchClose: builder.dispatch(batchClose),
  create: builder.dispatch(create),
  batchCreate: builder.dispatch(batchCreate),
  activateSampleCard: builder.dispatch(activateSampleCard),
  reissue: builder.dispatch(reissue),
  reissueCommercialCharge: builder.dispatch(reissueCommercialCharge),
  batchReissueCommercialCharge: builder.dispatch(batchReissueCommercialCharge),
  getSuggestedMerchants: builder.dispatch(getSuggestedMerchants),
  searchMerchants: builder.dispatch(searchMerchants),
  exportCommercialCharge: builder.dispatch(exportCommercialCharge),
  queueCommercialChargeReissue: builder.dispatch(queueCommercialChargeReissue),
  getMerchantCategories: builder.dispatch(getMerchantCategories),
  getBillingLink: builder.dispatch(getBillingLink),
  fetchTotalUserCards: builder.dispatch(fetchTotalUserCards),
  setCardNote: builder.dispatch(setCardNote),
  getLockedMerchant: builder.dispatch(getLockedMerchant),
};
