<template>
  <b-modal
    :id="modalId"
    hide-header
    hide-footer
    centered
    modal-class="card-create-modal"
    data-test="card-create"
    @show="onShow"
  >
    <div v-if="!!category">
      <h3 class="category-header">{{ category.name }}</h3>
      <p class="category-desc">{{ category.description }}</p>
    </div>
    <PrivacyCard :cardProp="card" class="mx-auto shadow-none mb-2" />
    <div class="d-flex justify-content-center py-2">
      <div
        class="nickname"
        @click="onClickNickname"
        data-test="card-details-nickname"
      >
        <div class="label">Nickname</div>
        <div class="value">
          {{ card.memo || card.hostname || "Untitled" }}
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column justify-content-between align-items-center px-3"
      style="gap: 8px"
    >
      <BaseButton
        class="button"
        v-if="showLockControl"
        :variant="lockVariant"
        @click="toggleCardType"
        aria-label="Toggle merchant lock"
        :title="`Card will ${lockTooltip} merchant locked`"
        v-b-tooltip.hover.d300
        :disabled="submitting"
      >
        <b-icon icon="unlock-fill" v-if="unlockedCard" scale="1.2" />
        <b-icon icon="lock-fill" v-else scale="1.2" />
      </BaseButton>
      <BaseButton
        class="button -next"
        type="submit"
        variant="primary"
        size="md"
        data-test="select-category"
        @click="handleCreate"
      >
        Create Card
      </BaseButton>
      <BaseButton
        class="button -cancel"
        size="md"
        variant="primary"
        data-test="select-category"
        @click="handleClose"
      >
        Cancel
      </BaseButton>
    </div>
    <EditNickname :card="card" @update-card="handleCardUpdate" />
  </b-modal>
</template>
<script lang="ts">
import { PERMISSION } from "@/constants";
import { cardStore, userStore } from "@/store";
import { Card, CardType } from "@/types/Card";
import { MerchantCategory } from "@/types/MerchantCategory";
import { Toast } from "@/mixins/Toast";
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { BIcon, BIconUnlockFill, BIconLockFill } from "bootstrap-vue";
import CardDetails from "./CardDetails.vue";
import PrivacyCard from "./PrivacyCard.vue";
import EditNickname from "./EditNickname.vue";

@Component({
  components: {
    PrivacyCard,
    CardDetails,
    BIcon,
    BIconUnlockFill,
    BIconLockFill,
    EditNickname,
  },
})
export default class CardCreate extends Mixins(Toast) {
  @Prop({ default: "card-create" }) modalId!: string;
  @Prop() type?: CardType;
  @Prop() category?: MerchantCategory;
  @Watch("category", { immediate: true })
  card: Partial<Card> = {};
  submitting = false;

  get user() {
    return userStore.getters.currentUser;
  }

  get hasUnlockedCardsPermission(): boolean {
    const permissions = (this.user?.permissions || []) as PERMISSION[];

    return permissions.some(
      (permission: PERMISSION) => permission === PERMISSION.UNLOCKED_CARDS
    );
  }

  get showLockControl(): boolean {
    const { type } = this.card;

    return (
      this.hasUnlockedCardsPermission &&
      type !== CardType.DIGITAL_WALLET &&
      type !== CardType.SINGLE_USE &&
      !this.card.merchantCategory
    );
  }

  get unlockedCard(): boolean {
    return this.card?.type === CardType.UNLOCKED && !this.card.merchantCategory;
  }

  get lockVariant(): string {
    return this.unlockedCard ? "white" : "dark";
  }

  get lockTooltip(): string {
    return this.unlockedCard ? "not be" : "be";
  }

  onShow(): void {
    this.setInitialCard();
  }

  onClickNickname() {
    this.$bvModal.show("edit-nickname");
  }

  setInitialCard(): void {
    const type =
      this.type ||
      (this.hasUnlockedCardsPermission
        ? CardType.UNLOCKED
        : CardType.MERCHANT_LOCKED);
    let customStyle;
    let memo;
    if (this.category) {
      customStyle = {
        icon: this.category.icon,
        bgColor: this.category.color,
      };
      memo = this.category.name;
    }

    this.card = {
      type,
      unused: true,
      merchantCategory: this.category?._id,
      memo,
      meta: {
        customStyle,
      },
      style: customStyle,
    };
  }

  toggleCardType(): void {
    const newType =
      this.card?.type === CardType.UNLOCKED
        ? CardType.MERCHANT_LOCKED
        : CardType.UNLOCKED;

    this.card!.type = newType;
  }

  handleCardUpdate(card: Card) {
    // only allow memo to be updated
    this.card = { ...this.card, memo: card.memo };
  }

  handleClose() {
    this.$bvModal.hide("card-create");
  }

  async handleCreate() {
    if (this.submitting || !this.card) {
      return;
    }

    this.submitting = true;

    try {
      const { data: card } = await cardStore.actions.create(this.card);
      this.$emit("create-success", card);
    } catch (err) {
      const error = err as any;
      const data = error.response?.data;

      let message =
        data?.message || error.message || "An unknown error occurred";

      if (data?.errors) {
        message = Object.values(data.errors).join("; ");
      }

      this.errorToast(message);
    } finally {
      this.submitting = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.details {
  margin: 25px 0 20px;
  padding: 10px;
  background-color: $white;
  border-radius: $radius-larger;
  filter: drop-shadow(0 10px 10px $color-shadow-black-faint);
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-bottom-color: white;
    border-top: 0;
    left: 50%;
    top: -15px;
    position: absolute;
    transform: translateX(-50%);
  }
}

.nickname {
  padding: 12px 16px;
  background-color: $foreground-border;
  border-radius: 8px;
  text-align: left;
  width: 310px;

  .label {
    font-family: $font-stack-graphik;
    font-size: 14px;
    font-weight: 600;
  }
}

.category-header {
  font-family: $font-stack-fk-grotesk;
  text-align: center;
}

.category-desc {
  font-family: $font-stack-graphik;
  text-align: center;
  color: $neutrals-4;
}

.field {
  width: 310px;
}

.button {
  width: 310px;
  &.-next {
    background-color: $brand-purple;
  }
  &.-cancel {
    background-color: #f5f7f8;
    color: $foreground-default;
  }
}
</style>
