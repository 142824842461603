<template>
  <div class="transaction-inspector" data-test="transaction-inspector">
    <div
      class="title"
      :class="{
        '-dispute-open':
          DisputeType.DISPUTE_OPEN === transaction.state ||
          DisputeType.DISPUTE_PENDING === transaction.state ||
          DisputeType.CHARGEBACK_PENDING === transaction.state,
      }"
    >
      <SVGIcon
        v-if="!fullPage"
        class="back-button"
        icon="chevron-left"
        @click="onPressBack"
      />
      <div class="descriptor">{{ transaction.descriptor }}</div>
    </div>
    <ul class="details">
      <li
        class="total"
        :class="{
          '-unconfirmed':
            transaction.state === DisputeType.ACTIVE &&
            !transaction.dateSettled,
          '-blocked': !transaction.accepted,
          '-disputed':
            transaction.state === DisputeType.DISPUTE_OPEN ||
            transaction.state === DisputeType.DISPUTE_PENDING ||
            transaction.state === DisputeType.CHARGEBACK_PENDING,
          '-refunded':
            transaction.state === DisputeType.DISPUTE_CLOSED ||
            transaction.amount < 0,
          '-bounced': transaction.settlementStatus === 'Bounced',
        }"
      >
        <span class="label">Total</span>
        <span class="value -before" v-if="transaction.promoCreditAmount">
          <DollarAmount :amount="transaction.amount" transaction />
        </span>
        <span class="value -promo" v-if="transaction.promoCreditAmount">
          <DollarAmount
            :amount="transaction.amount - transaction.promoCreditAmount"
          />
        </span>
        <span class="value" v-if="!transaction.promoCreditAmount">
          <DollarAmount :amount="transaction.amount" />
        </span>
      </li>
      <li
        class="foreign-transaction"
        v-if="transaction.calculatedFees && transaction.calculatedFees.FX"
      >
        <div class="tooltip-container">
          <span class="label">{{ fxFeeTitle }}</span>
          <div v-b-tooltip.hover :title="fxFeeTooltip">
            <img src="@/assets/icons/tooltip.svg" alt="" />
          </div>
        </div>
        <span class="foreign-fee">
          <DollarAmount :amount="transaction?.calculatedFees?.FX" />
        </span>
      </li>
      <li
        class="transaction-amount"
        v-if="transaction.totalFees && transaction.totalFees > 0"
      >
        <span class="label">Transaction Amount</span>
        <span class="value">
          <DollarAmount :amount="transaction.amount - transaction.totalFees" />
        </span>
      </li>
      <li v-if="showRefunds" class="refunds">
        <span class="label">Refunds</span>
        <span class="refund-amount">
          <DollarAmount :amount="-refundTotal" />
        </span>
      </li>
      <li v-if="transaction.cashback && transaction.cashback > 0">
        <span class="label">Cashback</span>
        <DollarAmount :amount="transaction.cashback" />
      </li>
      <li v-if="transaction.promoCreditAmount">
        <span class="label">Credit Applied</span>
        <span class="value -credit-applied">
          <DollarAmount :amount="transaction.promoCreditAmount" />
        </span>
      </li>
      <li v-if="transaction.state">
        <span class="label">Status</span>
        <span
          class="value text-capitalize"
          v-if="
            DisputeType.ACTIVE === transaction.state ||
            DisputeType.DISPUTE_APPROVED === transaction.state
          "
        >
          {{ settlementStatus }}
        </span>
        <span
          class="value -dispute-open"
          v-if="
            DisputeType.DISPUTE_OPEN === transaction.state ||
            DisputeType.DISPUTE_PENDING === transaction.state
          "
        >
          Dispute Open
        </span>
        <span
          class="value -chargeback-pending"
          v-if="DisputeType.CHARGEBACK_PENDING === transaction.state"
        >
          Chargeback Pending
        </span>
        <span
          class="value"
          v-if="DisputeType.DISPUTE_CLOSED === transaction.state"
        >
          Dispute Closed
        </span>
      </li>
      <li>
        <span class="label">Authorized</span>
        <span class="value">
          {{ dateAuthorized && dateToString(dateAuthorized, true) }}
        </span>
      </li>
      <li v-if="transaction.accepted && transaction.achReceived">
        <span class="label">Funded on</span>
        <span class="value -expected" v-if="transaction.achReceived">
          {{ dateToString(transaction.achReceived) }}
        </span>
      </li>
      <li
        class="funding-source"
        v-if="
          transaction.accepted &&
          transaction.fundingAccount &&
          transaction.fundingBank
        "
      >
        <span class="label">Bank</span>
        <span class="value">
          {{ transaction.fundingAccount }}
        </span>
      </li>
      <li v-if="card">
        <span class="label">Card used</span>
        <a class="value">
          {{ card.memo || card.hostname }}
        </a>
      </li>
    </ul>
    <div class="options">
      <BaseButton
        class="pill-button"
        variant="transparent"
        v-if="
          transaction.state !== DisputeType.DISPUTE_OPEN &&
          transaction.dateSettled &&
          !fullPage
        "
        @click="showDisputeModal()"
      >
        Open Dispute
      </BaseButton>
      <BaseButton
        class="pill-button"
        variant="transparent"
        v-if="transaction.state === DisputeType.DISPUTE_OPEN"
        @click="closeTransactionDispute()"
      >
        Close Dispute
      </BaseButton>
    </div>
  </div>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Card } from "@/types/Card";
import {
  Transaction as TransactionType,
  TransactionRefund,
} from "@/types/Transaction";
import { DisputeType } from "@/types/Dispute";
import { transactionStore } from "@/store";
import { formatDate, utcDate } from "@/lib/dates";
import SVGIcon from "./SVGIcon.vue";
import DollarAmount from "./DollarAmount.vue";

@Component({
  components: {
    SVGIcon,
    DollarAmount,
  },
})
export default class TransactionInspector extends Vue {
  @Prop() card!: Card;
  @Prop() transactionProp!: TransactionType;
  @Prop() fullPage?: boolean;

  DisputeType = DisputeType;

  get transaction(): TransactionType {
    const storeTransaction = transactionStore.getters.getTransactionById(
      this.transactionProp.transactionID
    );
    return storeTransaction || this.transactionProp;
  }

  get dateAuthorized(): string | Date | null {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const transaction = ldGet<any, string, any>(this, "transaction", {}) as any;
    return transaction.dateAuthorized || transaction.dateSettled;
  }

  get showRefunds(): boolean {
    const amount = ldGet<any, string>(this, "transaction.amount");
    const refunds = ldGet<any, string, TransactionRefund[]>(
      this,
      "transaction.refunds",
      []
    );

    return amount >= 0 && refunds.length > 0;
  }

  get refundTotal(): number {
    const refunds = ldGet<any, string, TransactionRefund[]>(
      this,
      "transaction.refunds",
      []
    );

    return refunds.reduce(
      (total: number, { amount }: { amount: number }) => total + Number(amount),
      0
    );
  }

  get settlementStatus() {
    return this.transaction?.settlementStatus?.toLowerCase();
  }

  get fxFeeTitle() {
    if (this.transaction?.isEstimatedFee) {
      return "Estimated Foreign Transaction Fee";
    }

    return "Foreign Transaction Fee";
  }

  get fxFeeTooltip() {
    const tooltipBase = "Foreign Transactions incur a 3% fee ($0.50 min)";

    if (this.transaction?.isEstimatedFee) {
      return tooltipBase + ". Actual fee will be based on settled amount";
    }

    return tooltipBase;
  }

  dateToString(date: Date | string, long = false) {
    const format = long ? "MMM DD, YYYY, h:mma" : "MMM DD, YYYY";
    return formatDate(format, utcDate(date, {}, true).local());
  }

  onPressBack(): void {
    this.$emit("on-press-back");
  }

  showDisputeModal(): void {
    this.$emit("show-dispute-modal", this.transaction);
  }

  closeTransactionDispute(): void {
    this.$emit("close-transaction-dispute", this.transaction);
  }
}
</script>
<style lang="scss" scoped>
.transaction-inspector {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  animation: $animation-fade-in-left $duration-shortest;

  > .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 20px;
    padding-top: 0px;
    font-family: $font-stack-wes-fy;
    font-size: 20px;
    font-weight: bold;

    &.-dispute-open {
      color: $color-orange;
    }
  }

  > .title > .descriptor {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > .title > .back-button {
    margin-right: 10px;
    margin-left: -5px;
    opacity: 0.5;
    transition: opacity $duration-shorter;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  > .details {
    padding: 0 20px;

    > li {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 0;
      min-height: 40px;
    }

    > li:last-child {
      border-bottom: 0;
    }

    > li > .label {
      flex-shrink: 0;
      margin-right: auto;
      padding-right: 10px;
      font-weight: bold;
    }

    > li > .tooltip-container {
      display: flex;
      align-items: center;
      margin-right: auto;

      span {
        flex-shrink: 0;
        margin-right: 8px;
        font-weight: bold;
      }

      div {
        display: flex;
      }
    }

    > li > .value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.-link {
        color: $blue;
        cursor: pointer;

        &:hover {
          filter: brightness(80%);
        }
      }
    }
  }

  > .details > .total > .value {
    font-weight: bold;

    &.-before {
      padding-right: 5px;
      font-size: 14px;
      font-weight: normal;
      text-decoration: line-through;
      color: $gray-500;
    }

    &.-promo {
      color: $blue;
    }

    &.-credit-applied {
      color: $color-green;
    }

    &.-dispute-open {
      color: $color-orange;
    }
  }

  > .details > .total.-unconfirmed > .value {
    color: $gray-500;
  }

  > .details > .total.-disputed > .value {
    color: $color-orange;
  }

  > .details > .total.-blocked > .value {
    text-decoration: line-through;
    color: $color-red;
  }

  > .details > .total.-refunded > .value {
    color: $color-green;
  }

  > .details > .total.-bounced > .value {
    color: $color-red;
  }

  > .options {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    padding: 10px;

    > .pill-button {
      justify-content: space-between;
      margin-right: 10px;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    @media #{$media-phone} {
      flex-direction: column;
      align-items: center;
      margin-left: 0;

      > .pill-button > a {
        margin-right: 0;
      }
    }
  }
}

.refunds .dollar-amount {
  color: $green;
}
</style>
