<template>
  <div>
    <div class="section-heading">
      <h2>Notifications</h2>
    </div>
    <div class="account-notifications section">
      <p>When should we send you email notifications?</p>
      <b-select
        class="dropdown"
        :disabled="loading"
        :loading="loading"
        v-model="user.transactionNotifications"
        :options="options"
        @change="onChangeNotificationSetting"
        data-test="notifications-select"
      />
      <p>You can edit your push notification settings in our mobile app.</p>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Mixins } from "vue-property-decorator";
import { NotificationSetting, User } from "@/types/User";
import { userStore } from "@/store";
import { Toast } from "@/mixins/Toast";

interface NotificationOption {
  text: string;
  value: NotificationSetting;
}

@Component
export default class Notifications extends Mixins(Toast) {
  @Prop({ required: true }) user!: User;
  loading = false;
  options: NotificationOption[] = [
    { text: "Never", value: "NONE" },
    { text: "On declines", value: "DECLINES" },
    { text: "On transactions and declines", value: "TRANSACTIONS" },
  ];

  onChangeNotificationSetting(setting: NotificationOption["value"]) {
    this.loading = true;
    userStore.actions
      .setTransactionNotifications({ notificationTypes: setting })
      .then(() => {
        userStore.mutations.updateCurrentUser({
          transactionNotifications: setting,
        });
        this.successToast(
          setting === "NONE"
            ? "Notifications disabled"
            : "Notifications updated"
        );
      })
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message ||
            "Sorry! There was a problem changing your notification settings"
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>
<style lang="scss" scoped></style>
