<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm :fullpage="fullpage" @submit="onSubmit" class="tfa-setup-form">
      <h1>Setup Authentication</h1>
      <p>
        <b-tooltip target="tfa-suggested-apps" triggers="hover"
          >Try 1Password, Google Authenticator, or Authy, available on iOS and
          Android</b-tooltip
        >
        <span id="tfa-suggested-apps">Using your authentication app</span>, scan
        the QR code or enter the 16-digit key, then enter the one-time access
        code your app generates below.
      </p>
      <div
        class="qrcode-container"
        :class="{ '-generating-seed': generatingSeed }"
      >
        <b-spinner
          small
          class="spinner"
          label="Loading..."
          v-if="generatingSeed"
        ></b-spinner>
        <div class="key">{{ tfaSeed }}</div>
        <canvas id="qrcode" class="qrcode"></canvas>
      </div>
      <b-alert
        v-if="errorMessage"
        variant="danger"
        show
        data-test="tfa-setup-error"
      >
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        label="One-Time Access Code"
        placeholder="000000"
        v-model="tfaResponse"
        max-length="6"
        :state="errorMessage ? false : null"
        :disabled="loading || success"
        data-test="tfa-setup-input"
      >
      </BaseInput>
      <BaseButton
        type="submit"
        variant="primary"
        size="lg"
        v-if="!success"
        :disabled="loading"
        data-test="tfa-setup-submit-button"
      >
        Enable
      </BaseButton>
      <BaseButton
        type="submit"
        variant="success"
        size="lg"
        v-if="success"
        :disabled="true"
        data-test="tfa-setup-success"
      >
        TFA Enabled
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import QRCode from "qrcode";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { eventStore, userStore } from "@/store";
import { Toast } from "@/mixins/Toast";

const SUCCESS_MESSAGE_DURATION = 1000;

@Component({})
export default class TFASetupForm extends Mixins(Toast) {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop() modalId?: string;
  errorMessage = "";
  tfaResponse = "";
  tfaSeed = "";
  generatingSeed = true;
  success = false;
  loading = false;

  @Watch("tfaSeed")
  resetErrorState() {
    this.errorMessage = "";
  }

  get user() {
    return userStore.getters.currentUser;
  }

  created() {
    // Wait until user exists before updating TFA seed
    const getUserPromise = new Promise((resolve) => {
      return this.user
        ? resolve(this.user)
        : userStore.actions.getCurrentUser();
    });
    getUserPromise.then(this.updateTwoFactorSeed);
  }

  updateTwoFactorSeed() {
    const serviceName = encodeURIComponent("Privacy.com");
    userStore.actions
      .getTfaSeed()
      // todo-ts: remove once actions are typed
      .then((res: any) => {
        const { TFASeed, TFAToken } = res.data;
        userStore.mutations.updateCurrentUser({ TFASeed, TFAToken });
        this.tfaSeed = TFASeed;
        const email = this.user?.email || "";

        const tfaUrl = `otpauth://totp/${serviceName}:${email}?secret=${TFASeed}&issuer=${serviceName}`;
        const canvas = document.getElementById("qrcode");
        QRCode.toCanvas(
          canvas,
          tfaUrl,
          {
            errorCorrectionLevel: "H",
            width: 160,
          },
          function (err: Error) {
            if (err) {
              eventStore.actions.error(err.message);
              throw err;
            }
          }
        );
        this.generatingSeed = false;
      })
      .catch(() => {
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  onSubmit() {
    this.resetErrorState();

    if (!this.user) {
      this.errorMessage = "Please log in to continue";
      return;
    }

    if (!this.tfaResponse.trim()) {
      this.errorMessage = "Please enter your one-time access code";
      return;
    }

    this.loading = true;
    userStore.actions
      .setupTfa(this.tfaResponse)
      .then(() => {
        this.success = true;
        this.loading = false;

        setTimeout(() => {
          if (this.fullpage) {
            this.$router.push({
              name: "home",
            });
          } else if (this.modalId) {
            this.$bvModal.hide(this.modalId);
            this.successToast("Your 2FA settings have been updated.");
          }
        }, SUCCESS_MESSAGE_DURATION);
      })
      .catch((err) => {
        this.loading = false;
        this.tfaResponse = "";
        this.errorMessage =
          err.response.data.message || "There was an error. Please try again.";
      });
  }
}
</script>
<style lang="scss" scoped>
.tfa-setup-form {
  > h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }

  > .qrcode-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    height: 250px;
    box-shadow: inset 0 0 0 2px mix($gray-400, $gray-200, 25%);
    border-radius: $border-radius-lg;
    font-family: $font-stack-ocr;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 10px;
    color: $black;

    > .spinner {
      position: absolute;
    }

    > .key {
      margin-bottom: 20px;
    }

    > .qrcode {
      height: 160px;
      width: 160px;
    }

    > .key,
    > .qrcode {
      transition: opacity $duration-short;
    }
  }

  > .qrcode-container.-generating-seed > .key,
  > .qrcode-container.-generating-seed > .qrcode {
    opacity: 0;
  }

  #tfa-suggested-apps {
    border-bottom: 1px dotted $gray-600;
  }

  @media #{$media-phone} {
    padding: 30px;
    max-width: 100%;
  }
}
</style>
