<template>
  <div class="search-results" ref="searchResults">
    <div class="loading" v-if="state === searchResultsState.LOADING">
      <b-spinner></b-spinner>
    </div>
    <div
      class="zero-state"
      v-if="
        state === searchResultsState.RESULTS &&
        !cardResults.length &&
        !transactionResults.length
      "
    >
      Nothing found
    </div>
    <div
      class="mobile-tabs"
      v-if="
        (state === searchResultsState.RESULTS && cardResults.length) ||
        transactionResults.length
      "
    >
      <div
        class="tab"
        @click="setMobileTab(mobileTab.CARDS)"
        :class="{ '-active': activeMobileTab === mobileTab.CARDS }"
      >
        {{ cardResults.length }}
        {{ cardResults.length === 1 ? "Card" : "Cards" }}
      </div>
      <div
        class="tab"
        @click="setMobileTab(mobileTab.TRANSACTIONS)"
        :class="{ '-active': activeMobileTab === 'TRANSACTIONS' }"
      >
        {{ transactionResults.length }}
        {{ transactionResults.length === 1 ? "Transaction" : "Transactions" }}
      </div>
    </div>
    <div
      class="cards"
      v-if="state === searchResultsState.RESULTS"
      :class="{ '-active-mobile-view': activeMobileTab === mobileTab.CARDS }"
    >
      <div class="title">
        {{ totalCardCount }} {{ totalCardCount === 1 ? "Card" : "Cards" }}
      </div>
      <CardList
        :cardsProp="cardResults"
        @click-card="inspectCard"
        :limitProp="cardLimit"
        :paginate="true"
        :node-paginate="true"
        :query="query"
        :foundAllcards="foundAllCards"
      />
    </div>
    <div
      class="transactions"
      v-if="state === searchResultsState.RESULTS"
      :class="{
        '-active-mobile-view': activeMobileTab === mobileTab.TRANSACTIONS,
      }"
    >
      <div class="title">
        {{ totalTransactionCount }}
        {{ totalTransactionCount === 1 ? "Transaction" : "Transactions" }}
      </div>
      <TransactionListComponent
        :transactionsProp="transactionResults"
        @click-transaction="inspectTransaction"
        :limitProp="transactionLimit"
        :hideColumns="{ settlement: true, declineReason: true }"
        :showDateBuckets="true"
        :paginate="true"
        :nodePaginate="true"
        :query="query"
        :foundAllTransactionsProp="foundAllTransactions"
      />
    </div>
    <CardInspector
      id="card-inspector"
      modalId="card-inspector-search-results"
      :transactionProp="currentTransaction"
      :cardProp="currentCard"
      @hide="handleCloseCardInspector"
      @switch="switchCurrentCard"
    ></CardInspector>
  </div>
</template>
<script lang="ts">
import ldGet from "lodash/get";
import { Watch, Component, Vue, Prop } from "vue-property-decorator";
import { card as cardValidator, card } from "creditcards";
import { TransactionList, Transaction } from "@/types/Transaction";
import { Card } from "@/types/Card";
import { cardStore, transactionStore, userStore } from "@/store";
import CardList from "../components/CardList.vue";
import TransactionListComponent from "../components/TransactionList.vue";
import CardInspector from "../components/CardInspector.vue";

enum mobileTab {
  CARDS = "CARDS",
  TRANSACTIONS = "TRANSACTIONS",
}

enum searchResultsState {
  LOADING = "LOADING",
  RESULTS = "RESULTS",
}

let didJustCloseModal = false;

const lastFourRegex = /^(?:\d{4})/;

@Component({
  components: {
    CardList,
    TransactionListComponent,
    CardInspector,
  },
})
export default class SearchResults extends Vue {
  @Prop({ default: "" }) query!: string;

  state: searchResultsState = searchResultsState.LOADING;
  cardResults: Card[] = [];
  transactionResults: Transaction[] = [];
  searchTimeout: number | undefined = undefined;
  isFetching = false;
  dataFetched = false;
  transactionList: TransactionList = {
    all: [],
    declines: [],
  };
  cardLimit = 10;
  transactionLimit = 20;
  foundAllTransactions = false;
  foundAllCards = false;
  totalCardCount = 0;
  totalTransactionCount = 0;
  currentCard: Card | null = null;
  currentTransaction: Transaction | null = null;
  activeMobileTab: mobileTab = mobileTab.CARDS;
  mobileTab = mobileTab;
  searchResultsState = searchResultsState;

  get user() {
    return userStore.getters.currentUser;
  }

  created() {
    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("keydown", this.handleKeyDown);
  }

  destroyed() {
    window.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  mounted() {
    this.updateResults();
  }

  handleClickOutside(event: MouseEvent) {
    event.stopPropagation();
    const el = this.$refs.searchResults as HTMLElement;
    const eventTarget = event.target as HTMLElement;

    if (eventTarget && el && !el.contains(eventTarget)) {
      // allow clicking in the card inspector
      if (this.currentCard || this.currentTransaction || didJustCloseModal) {
        didJustCloseModal = false;
        return;
      }

      this.close();
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape" || event.code === "Escape") {
      this.close();
    }
  }

  @Watch("query")
  updateResults() {
    this.foundAllTransactions = true;
    this.foundAllCards = true;
    this.cardResults = [];
    this.transactionResults = [];
    this.totalCardCount = 0;
    this.totalTransactionCount = 0;

    this.state = searchResultsState.LOADING;

    const queryRateLimit = 750;
    window.clearTimeout(this.searchTimeout);
    this.searchTimeout = window.setTimeout(() => {
      this.query = this.query.toLowerCase();

      Promise.all([
        cardStore.actions.getCards({
          limit: this.cardLimit,
          query: this.query,
        }),
        transactionStore.actions.fetchTransactions({
          limit: this.transactionLimit,
          query: this.query,
        }),
      ])
        .then((results) => {
          // cards
          const cardResult = results[0];
          this.cardResults = ldGet<any, string, any[]>(cardResult, "all", []);
          if (cardResult.totalCards) {
            this.totalCardCount = cardResult.totalCards;
          }
          if (this.cardResults.length === cardResult.totalCards) {
            this.foundAllCards = true;
          } else {
            this.foundAllCards = false;
          }
          // transactions
          const transactionResult = results[1];
          this.transactionResults = ldGet<any, string, any[]>(
            transactionResult,
            "all",
            []
          );
          if (transactionResult.totalTransactions) {
            this.totalTransactionCount = transactionResult.totalTransactions;
          }
          this.state = searchResultsState.RESULTS;
          if (this.transactionResults.length && !this.cardResults.length) {
            this.setMobileTab(mobileTab.TRANSACTIONS);
          } else {
            this.setMobileTab(mobileTab.CARDS);
          }
          if (
            this.transactionResults.length ===
            transactionResult.totalTransactions
          ) {
            this.foundAllTransactions = true;
          } else {
            this.foundAllTransactions = false;
          }

          let term = this.query;

          if (cardValidator.isValid(card.parse(term))) {
            term = "[redacted pan]";
          } else if (lastFourRegex.test(term)) {
            term = "[last four]";
          }

          this.$piwik.trackSiteSearch(term, {
            searchCount: this.totalCardCount + this.totalTransactionCount,
          });
        })
        .finally(() => {
          this.state = searchResultsState.RESULTS;
          if (this.transactionResults.length && !this.cardResults.length) {
            this.setMobileTab(mobileTab.TRANSACTIONS);
          } else {
            this.setMobileTab(mobileTab.CARDS);
          }
        });
    }, queryRateLimit);
  }

  inspectCard(card: Card) {
    this.currentCard = card;
    this.$bvModal.show("card-inspector-search-results");
  }

  switchCurrentCard(cardID: number): void {
    const oldCard = cardStore.getters.getCard(cardID);
    if (oldCard) {
      this.currentCard = oldCard;
    }
  }

  inspectTransaction(transaction: Transaction) {
    this.currentTransaction = transaction;
    this.$bvModal.show("card-inspector-search-results");
  }

  close() {
    this.$emit("search-results-closed");
  }

  handleCloseCardInspector() {
    didJustCloseModal = true;
    this.currentCard = null;
    this.currentTransaction = null;
  }

  setMobileTab(tab: mobileTab) {
    if (tab === mobileTab.TRANSACTIONS && !this.transactionResults.length) {
      return;
    }

    if (tab === mobileTab.CARDS && !this.cardResults.length) {
      return;
    }

    this.activeMobileTab = tab;
  }
}
</script>
<style lang="scss" scoped>
.search-results {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-self: flex-start;
  justify-content: stretch;
  margin-top: 57px;
  margin-left: 25px;
  padding: 40px 60px 0;
  height: calc(100vh - 80px);
  width: 840px;
  min-width: 670px;
  max-width: calc(100vw - 40px);
  overflow-y: auto;
  background: $white;
  box-shadow: $box-shadow-hairline, $box-shadow-large;
  border-radius: $radius-large;
  animation: $animation-fade-in-down;
  z-index: 102;

  @media #{$media-phone} {
    top: 0;
    right: 10px;
    left: 10px;
    flex-direction: column;
    margin-left: 0;
    padding: 0;
    height: calc(100vh - 57px - 100px);
    width: unset;
    min-width: unset;
    max-width: unset;
  }

  > .loading {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > .zero-state {
    margin: auto;
    font-family: $font-stack-wes-fy;
    font-size: 20px;
    text-align: center;
    color: $gray-400;
  }

  > .zero-state ~ .mobile-tabs,
  > .zero-state ~ .cards,
  > .zero-state ~ .transactions {
    display: none;
  }

  > .mobile-tabs {
    display: flex;
    flex-basis: 60px;
    flex-shrink: 0;
    align-items: stretch;
    margin-bottom: 10px;
    padding: 0 20px;
    box-shadow: inset 0 -1px $shadow-black-faint;
    font-family: $font-stack-wes-fy;
    font-size: 16px;

    @media #{$media-desktop} {
      display: none;
    }
  }

  > .mobile-tabs > .tab {
    display: flex;
    align-items: center;
    margin: 0 10px;
    color: $gray-400;

    &.-active {
      box-shadow: inset 0 -1px $gray-800;
      color: $gray-800;
    }
  }

  > .cards {
    .pagination-controls {
      max-width: 245px;
    }
  }

  > .cards,
  > .transactions {
    display: flex;
    flex-direction: column;
    // fixes iOS Safari rendering bug where off-screen elements are not rendered
    transform: 0;
    -webkit-transform: translate3d(0, 0, 0);

    @media #{$media-phone} {
      align-items: center;
      padding: 20px 20px 0;

      &:not(.-active-mobile-view) {
        display: none;
      }
    }
  }

  > .cards > .title,
  > .transactions > .title {
    margin-bottom: 30px;
    font-family: $font-stack-wes-fy;
    font-size: 20px;

    @media #{$media-phone} {
      display: none;
    }
  }

  > .cards > .card-list,
  > .transactions > .transaction-list {
    display: block;
    padding-bottom: 40px;
  }

  > .cards {
    flex-basis: 290px;
    flex-shrink: 0;
  }

  > .transactions {
    flex-grow: 1;

    > .transaction-list {
      flex-grow: 1;
      margin: -30px 0 0;
      padding-top: 30px;
      padding-right: 0;
      padding-left: 0;

      // can't use standard breakpoint since component is within container
      @media #{$media-width-880} {
        ::v-deep .transaction > .left,
        ::v-deep .transaction > .right {
          flex-direction: column-reverse;
          align-items: flex-start;
          justify-content: space-between;
          height: 50px;
        }

        ::v-deep .transaction > .right {
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
        }

        ::v-deep .transaction > .left > .descriptor {
          flex-grow: 0;
        }
      }

      @media #{$media-phone} {
        align-self: stretch;
        padding-top: 15px;
      }

      > .zero-state {
        margin-top: -15px;
      }
    }
  }
}
</style>
