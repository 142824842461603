<template>
  <SimplePage :fullpage="true">
    <BaseForm id="reset-password" @submit="resetPassword" :fullpage="true">
      <h3 class="title">Set Your Password</h3>
      <p class="blurb">Choose a new password for your account.</p>
      <b-alert
        variant="danger"
        :show="errorMessage.length > 0"
        data-test="alert-reset-error"
      >
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        label="New Password"
        placeholder="Your new password"
        type="password"
        maxlength="128"
        :autofocus="true"
        v-model="password"
        :disabled="loading"
        data-test="password"
        :aria-label="passwordFeedback"
      >
        <PasswordStrengthMeter
          :password="password"
          @update="updatePasswordValidity"
        />
      </BaseInput>
      <BaseInput
        label="Confirm New Password"
        placeholder="Enter your new password again"
        type="password"
        maxlength="128"
        v-model="password2"
        data-test="password2"
      >
      </BaseInput>
      <BaseButton type="submit" variant="primary" size="lg" :loading="loading">
        Set Password
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>
<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import PasswordStrengthMeter, {
  PasswordStrengthMixin,
} from "../components/PasswordStrengthMeter.vue";
import { userStore } from "../store";

@Component({
  components: {
    PasswordStrengthMeter,
  },
})
export default class Reset extends Mixins(PasswordStrengthMixin) {
  loading = false;
  password = "";
  password2 = "";
  errorMessage = "";

  resetPassword() {
    if (this.loading) {
      return false;
    }

    if (this.password !== this.password2) {
      this.errorMessage = "Passwords must match";
      return;
    }

    if (!this.passwordValid) {
      this.errorMessage = this.passwordFeedback;
      return;
    }

    const data = {
      id: this.$route.params.userId,
      newPassword: this.password,
      resetToken: this.$route.params.resetToken,
    };

    this.loading = true;
    userStore.actions
      .resetPassword(data)
      .then(() => {
        this.$router.push({
          name: "home",
        });
      })
      .catch((error) => {
        this.loading = false;
        const defaultMsg =
          "There was an error. Have you already reset your password? If not please email support";
        this.errorMessage = error?.response?.data?.message || defaultMsg;
      });
  }
}
</script>
<style lang="scss" scoped></style>
