<template>
  <SimplePage
    :fullpage="false"
    class="downgrade-warning-container d-flex flex-column mt-5 justify-content-start"
  >
    <BaseButton variant="text" @click="back" class="mt-2 mb-3" size="sm">
      <SVGIcon icon="arrow-left" /> All Options
    </BaseButton>
    <div class="downgrade-warning-card d-flex flex-column mx-auto p-4">
      <div style="border-bottom: 1px solid #f0f0f5" class="text-center">
        <SVGIcon class="pb-3 error-icon" icon="error" size="16" />
        <p>An important note about downgrading to a Personal plan.</p>
      </div>

      <div style="border-bottom: 1px solid #f0f0f5">
        <h4 class="heading">Category Cards</h4>
        <p>
          Once your plan ends, all Category-Locked Cards in your wallet will
          automatically become disabled.
        </p>
      </div>
      <div class="text-center">
        <span class="d-block font-weight-bold mb-4">
          Are you sure you want to downgrade?
        </span>
        <BaseButton
          variant="danger"
          class="select-plan-button"
          @click="submit"
          block
          size="md"
        >
          Yes, downgrade to Personal
        </BaseButton>
        <BaseButton
          variant="light"
          class="select-plan-button"
          @click="deny"
          block
          size="md"
        >
          No, keep my current plan
        </BaseButton>
      </div>
    </div>
    <BaseButton
      v-if="true"
      variant="text"
      @click="cancel"
      block
      class="mt-2"
      size="sm"
    >
      Cancel
    </BaseButton>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { Confirm } from "@/mixins/Confirm";
import SVGIcon from "@/components/SVGIcon.vue";
import { subscriptionStore } from "@/store";
import { SubscriptionState } from "@/types/Subscription";
import { formatDate, utcDate } from "@/lib/dates";
import { Toast } from "@/mixins/Toast";

@Component({
  components: {
    SVGIcon,
  },
})
export default class DowngradeWarning extends Mixins(Confirm, Toast) {
  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  deny() {
    this.$piwik.trackClick({ name: "Category Cards - Downgrade Deny CTA" });
    this.$router.push("/account");
  }

  cancel() {
    this.$piwik.trackClick({ name: "Category Cards - Downgrade Cancel CTA" });
    this.$router.push("/select-plan");
  }

  async submit() {
    let subscription;
    try {
      subscription = await subscriptionStore.actions.delete(
        this.subscription!.subscriptionUuid
      );
    } catch (err) {
      this.errorToast(
        "There was an error downgrading your plan. Please try again"
      );
      return;
    }

    let endDate = "";

    if (subscription.state === SubscriptionState.CANCELLED) {
      endDate += " It will remain active until ";

      endDate += subscription.endDate
        ? formatDate("MM/DD/YYYY", utcDate(subscription.endDate))
        : "the end of the current billing period.";
    }

    await this.confirm(`Your subscription has been cancelled.${endDate}`, {
      title: "Subscription Cancelled",
      okOnly: true,
    });
    this.$piwik.trackClick({ name: "Category Cards - Downgrade Accept CTA" });
    this.$router.push({ name: "account" });
  }

  back() {
    this.$piwik.trackClick({
      name: "Category Cards - Downgrade All options CTA",
    });
    this.$router.push("/select-plan");
  }
}
</script>

<style lang="scss" scoped>
.downgrade-warning-container {
  background: #f0f0f5;
  min-height: calc(100vh);
  padding-top: 48px;
}

.option-link {
  font-size: 14px;
  color: $gray-800;
}

.heading {
  font-size: 14px;
  font-weight: 600;
}

.error-icon {
  color: $danger-default;
}

.downgrade-warning-card {
  max-width: 357px;
  background: white;
  border-radius: 16px;
  gap: 24px;
  box-shadow:
    0px 2px 8px 0px rgba(0, 0, 0, 0.08),
    0px 0px 0px 1px rgba(0, 0, 0, 0.08);
}
p {
  color: #323242;
}
</style>
