<template>
  <BaseForm
    class="signup-step -login"
    @submit="tfa"
    :class="{
      error: submitted && errorMessage.length > 0,
      '-loading': waiting,
    }"
    novalidate="novalidate"
  >
    <div class="form-header">
      <h1>Two-Factor Auth</h1>
      <a class="header-link" @click="goBack">Go Back</a>
    </div>
    <div v-if="authMessage" class="blurb -left">
      {{ authMessage }}
      <br />
      <a v-if="twoFactorAuth" @click="goRecovery">
        Don't have a code? Reset your TFA preference.
      </a>
      <a v-else-if="!oneTimeCodeResent" @click="resendOneTimeCode">
        Send me a new code.
      </a>
    </div>
    <div class="blurb -left" v-else-if="twoFactorAuth">
      Please enter your time-based one-time access code below.
      <a @click="goRecovery">Don't have a code? Reset your TFA preference.</a>
    </div>
    <div class="blurb -left" v-else>
      We've emailed you a one-time access code, please enter it below.
      <a v-if="!oneTimeCodeResent" @click="resendOneTimeCode">
        Send me a new code.
      </a>
    </div>
    <div class="error-message" v-if="errorMessage.length > 0">
      {{ errorMessage }}
    </div>
    <BaseInput
      name="token"
      label="Access Code"
      placeholder="000000"
      v-model="token"
      type="text"
      max-length="6"
      :autofocus="true"
      autocomplete="one-time-code"
      :state="errorMessage ? false : null"
    ></BaseInput>
    <label class="account-checkbox">
      <input class="checkbox" v-model="tfaRememberDevice" type="checkbox" />
      <p class="m-0">Remember this device for 90 days</p>
    </label>
    <BaseButton class="p-3" variant="primary" @click="tfa" :loading="waiting"
      >Log In</BaseButton
    >
  </BaseForm>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";

import { Toast } from "../../mixins/Toast";
import {
  featureStore,
  loginStore,
  subscriptionStore,
  userStore,
} from "../../store";

import BaseForm from "../../components/BaseForm.vue";
import BaseButton from "../../components/BaseButton.vue";
import BaseInput from "../../components/BaseInput.vue";

const DELAY_TO_ALLOW_RESEND_AGAIN = 30000;

@Component({
  components: {
    BaseButton,
    BaseForm,
    BaseInput,
  },
})
export default class TwoFactorAuth extends Mixins(Toast) {
  waiting = false;
  submitted = false;
  oneTimeCodeResent = false;
  get userToken() {
    return userStore.getters.userToken;
  }
  get twoFactorAuth() {
    return userStore.getters.currentUser?.twoFactorAuth;
  }
  get oneTimeCode() {
    return userStore.getters.oneTimeCode;
  }
  get authMessage() {
    return userStore.getters.authMessage;
  }
  tfaRememberDevice = false;
  errorMessage = "";
  token = "";

  created() {
    if (!this.userToken) {
      this.$router.push({ name: "login" });
    }
  }

  resendOneTimeCode() {
    userStore.actions
      .resendOneTimeCode({ userToken: this.userToken || "" })
      .then(({ data }: any) => {
        if (data.message) {
          this.successToast(data.message);
        }
        this.errorMessage = "";
      })
      .catch(({ response }) => {
        this.errorMessage =
          response?.data?.message ||
          "Error resending the one-time access code...";
      });

    this.submitted = true;
    this.oneTimeCodeResent = true;

    // Let the user retry resending the one-time access code again after a little bit
    window.setTimeout(() => {
      this.oneTimeCodeResent = false;
    }, DELAY_TO_ALLOW_RESEND_AGAIN);
  }

  tfa() {
    this.waiting = false;
    this.submitted = true;

    this.$piwik.trackClick({ name: "Log in" });

    const loginFn = () => {
      const payload: any = {
        token: this.token,
        userToken: this.userToken || "",
        rememberDevice: this.tfaRememberDevice,
      };

      if (!this.twoFactorAuth) {
        delete payload.token;
        payload.code = this.token;
      }

      return userStore.actions.TFALogin(payload);
    };

    if (this.token.length) {
      this.waiting = true;
      loginFn()
        .then(() => {
          subscriptionStore.actions.fetchPlans();
          subscriptionStore.actions.fetchSubscription();
          featureStore.actions.fetchAll();

          const afterAuth = loginStore.getters.afterAuth;
          loginStore.mutations.setAfterAuth(null);

          if (afterAuth?.name) {
            const { name } = afterAuth;
            return this.$router.push({
              name,
            });
          }

          this.$router.push({ name: "home" });
        })
        .catch((err) => {
          this.waiting = false;

          if (err.sendBack) {
            return this.goBack();
          }
          this.errorMessage =
            err.response?.data?.message ||
            "There was an error. Please try again.";
        });
    } else {
      this.errorMessage = "Please enter your access code.";
    }
  }

  goRecovery() {
    userStore.mutations.setUserToken(this.userToken || "");
    this.$router.push({ name: "tfa-recovery" });
  }

  goBack() {
    userStore.mutations.clearCurrentUser();
    this.$router.push({ name: "login" });
  }
}
</script>

<style lang="scss" scoped></style>
