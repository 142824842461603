<template>
  <div class="pwp-bank-img">
    <img
      v-if="bankSrc"
      :src="defaultImg || bankSrc"
      :width="width"
      :height="height"
      @error="defaultImg = `url('/assets/images/icons/bank-40.svg')`"
      alt=""
    />
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

const instIDtoBankType: { [key: string]: string } = {
  ins_1: "bofa",
  ins_2: "bbt",
  ins_3: "chase",
  ins_4: "wells",
  ins_5: "citi",
  ins_6: "us",
  ins_7: "usaa",
  ins_9: "capone",
  ins_10: "amex",
  ins_11: "schwab",
  ins_12: "fidelity",
  ins_13: "pnc",
  ins_14: "td",
  ins_15: "nfcu",
  ins_16: "suntrust",
  ins_19: "regions",
  ins_20: "citizens",
  ins_21: "huntington",
  ins_22: "paypal",
  ins_23: "bbvac",
  ins_24: "simple",
  ins_25: "ally",
  ins_26: "fifth-third",
  ins_27: "mtb",
  ins_28: "santander",
  ins_29: "keybank",
  ins_31: "union",
  ins_32: "woodforest",
  ins_33: "discover",
  ins_34: "gsbank",
  ins_35: "chime",
  ins_36: "nc-secu",
  ins_51: "barclaycard",
  ins_52: "marcus",
  ins_53: "svb-connect",
  ins_56: "chase",
  ins_113800: "sffire",
  ins_127989: "bofa",
  ins_127990: "us",
  ins_127991: "wells",
  ins_128026: "capone",
};

@Component
export default class BankImage extends Vue {
  @Prop({ required: false }) width?: number;
  @Prop({ required: false }) height?: number;
  @Prop({ required: false }) bank?: string;

  defaultImg = "";

  get bankSrc() {
    if (!this.bank) {
      return "";
    }

    if (this.bank.includes("ins_") || !isNaN(parseInt(this.bank))) {
      return !instIDtoBankType[this.bank]
        ? `https://s3.amazonaws.com/privacy-web/images/bank/${this.bank}.png`
        : `/assets/images/banks/${instIDtoBankType[this.bank]}@2x.png`;
    }

    return `/assets/images/banks/${this.bank}@2x.png`;
  }
}
</script>
<style lang="scss" scoped></style>
