<template>
  <b-modal
    centered
    hide-footer
    title="Create New Card"
    header-class="type-modal-header"
    content-class="shadow"
    body-class="type-modal-body"
    id="card-type-modal"
    data-test="card-type-modal"
  >
    <button
      aria-label="Virtual Card"
      @click="select('')"
      data-test="select-locked-card-type"
    >
      <b-card
        border-variant="white"
        class="card-type"
        title="Virtual Card"
        body-class="virtual-cards"
      >
        <p>Create a virtual card for your online purchases or phone orders.</p>
      </b-card>
    </button>
    <button
      v-if="canCreateDigitalWalletCards"
      aria-label="Digital Wallet Card"
      @click="select('dw')"
      data-test="select-dw-card-type"
    >
      <b-card
        border-variant="white"
        class="card-type"
        title="Digital Wallet Card"
        body-class="dw-cards"
      >
        <p>
          Create an unlocked Digital Wallet card, which can be reused at
          multiple merchants.
        </p>
      </b-card>
    </button>
    <button
      aria-label="Category-Locked Card"
      @click="select('cl')"
      data-test="select-cl-card-type"
      :disabled="!canCreateCategoryCards"
      class="category-card-button"
    >
      <b-card
        border-variant="white"
        class="card-type"
        title="Category-Locked Card"
        body-class="cl-cards"
        :class="{ '-with-promo': !canCreateCategoryCards }"
      >
        <p>
          Create a virtual card to use with multiple merchants that qualify for
          specific category types.
        </p>
      </b-card>
      <div
        v-if="!canCreateCategoryCards"
        class="d-flex justify-content-space-between upgrade-prompt p-2"
      >
        <router-link to="/select-plan" class="upgrade-button">
          <b-icon icon="lock-fill" variant="white" size="sm" />
          Upgrade Required
        </router-link>
        <p class="category-card-upgrade">
          Upgrade to a paid plan to enable Category-Locked cards
        </p>
      </div>
    </button>
  </b-modal>
</template>

<script lang="ts">
import { CardType } from "@/types/Card";
import { Component, Emit, Vue } from "vue-property-decorator";
import { subscriptionStore, featureStore } from "@/store";
import { FeatureFlags } from "@/types/LaunchDarkly";
import { BIcon, BIconLockFill } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconLockFill,
  },
})
export default class SelectCardTypeModal extends Vue {
  @Emit()
  select(type: string): CardType | string {
    this.$bvModal.hide("card-type-modal");

    if (type === "dw") {
      return CardType.DIGITAL_WALLET;
    } else if (type === "cl") {
      return CardType.UNLOCKED;
    }

    return "";
  }

  get canCreateCategoryCards() {
    return !!this.subscription?.features.categoryCards;
  }

  get canCreateDigitalWalletCards() {
    return !!featureStore.getters.flag(FeatureFlags.DIGITAL_WALLET);
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  mounted(): void {
    featureStore.actions.fetchFlag({ id: FeatureFlags.DIGITAL_WALLET });
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .type-modal-header {
    padding: 32px 32px 0;
    font-family: $font-stack-graphik;
  }

  .type-modal-body {
    padding: 32px;
  }
}

button {
  margin: 0 0 12px;
  padding: 0;
  border: none;
  background: none;
  text-align: left;
}

.category-card-button:disabled ::v-deep .card-title {
  color: #000000;
}

.category-card-upgrade {
  color: #323242;
}

.card-type {
  background-color: #f5f7f8;
  font-family: $font-stack-graphik;
  overflow: hidden;

  &.-with-promo {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 1px solid #e0e0e0 !important;
    border-left: 1px solid #e0e0e0 !important;
    border-right: 1px solid #e0e0e0 !important;
    border-bottom: 0px;
  }

  p {
    color: #7b7c7c;
    margin: 0 40% 0 0;
  }
}

.virtual-cards {
  background: url(/assets/images/misc/virtual-cards.svg) no-repeat;
  background-position: right top;
}

.dw-cards {
  background: url(/assets/images/misc/digital-wallet.svg) no-repeat;
  background-position: right -150px top -50px;
}

.cl-cards {
  background: url(/assets/images/misc/category-locked-cards.svg) no-repeat;
  background-position: right bottom;
}

.upgrade-prompt {
  border: 1px solid #e0e0e0;
  border-top: 0px;
  border-radius: 0 0 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 24px;

  p {
    flex-basis: 50%;
    font-family: $font-stack-graphik;
    margin-bottom: 0;
  }
}
.upgrade-button {
  background-color: $brand-purple;
  padding: 4px 12px;
  height: 32px;
  border-radius: 40px;
  margin-bottom: 0;
  text-decoration: none;
  color: white;

  &:hover {
    background-color: $brand-purple;
  }
}
</style>
