<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      @submit="submit"
      :fullpage="fullpage"
      :class="{ '-loading': waiting }"
    >
      <h1>Confirmation Charge</h1>
      <p class="blurb">
        We made a small charge to your card. Enter it below to confirm that you
        own the card: <br />
        <strong>The charge is temporary and will be reversed.</strong>
      </p>
      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        class="mt-2"
        name="amount"
        label="Confirmation Amount"
        placeholder="0.00"
        v-model="amount"
        type="text"
        max-length="5"
        :state="amountError ? false : null"
        autocomplete="off"
        :autofocus="true"
        format="dollar-microauth"
      ></BaseInput>
      <BaseButton
        variant="primary"
        type="submit"
        :loading="waiting"
        size="lg"
        data-test="confirm-microauth-button"
        @click="trackClick"
      >
        Next
      </BaseButton>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { AxiosError } from "axios";
import { Component, Vue, Prop } from "vue-property-decorator";

import { BAlert } from "bootstrap-vue";
import { User } from "@/types/User";
import { fundingCardStore, userStore } from "@/store";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

const DEFAULT_ERROR = "Error submitting. Please try again later.";

@Component({
  components: {
    BAlert,
  },
})
export default class ConfirmCard extends Vue {
  @Prop({ default: false }) fullpage!: boolean;

  user: User | null = null;
  amount = "";
  errorMessage = "";
  amountError = false;
  waiting = false;

  created() {
    userStore.actions.getCurrentUser().then(() => {
      this.user = userStore.getters.currentUser;
    });
  }

  trackClick(): void {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.CONFIRM_CARD)} Primary CTA`,
    });
  }

  submit() {
    this.errorMessage = "";

    if (this.amount.length) {
      this.amountError = false;
    } else {
      this.amountError = true;
      this.errorMessage = "Please enter an amount";
      return;
    }

    this.waiting = true;

    fundingCardStore.actions
      .getCardFromUser({ user: this.user! })
      .then((fundingCard) => {
        if (!fundingCard?.uuid || fundingCard.state !== "PENDING") {
          this.errorMessage = "There are no cards pending verification";
          return;
        }

        const amount = Number(this.amount.slice(1));
        return fundingCardStore.actions
          .validate({
            uuid: fundingCard.uuid,
            amount,
          })
          .then(({ data }: any) => {
            this.user!.fundingCardList.forEach((fCard) => {
              if (fCard.uuid === fundingCard.uuid) {
                fCard.state = "ENABLED";
              }
            });
            const verificationNeeded = data.verificationNeeded || false;
            userStore.mutations.updateCurrentUser({
              fundingCardList: this.user!.fundingCardList,
              verificationNeeded,
              hasFundingSource: true,
              hasHadAnyFundingSource: true,
            });

            userStore.actions.getAccountInfo().then((accountInfo) => {
              const fundingCardBatchStatus = accountInfo.fundingCardBatchStatus;
              if (fundingCardBatchStatus) {
                this.$emit("complete-step", OnboardingSteps.SUCCESS_CARD_BATCH);
              } else {
                this.$emit("complete-step", OnboardingSteps.SUCCESS_CARD);
              }
            });
          });
      })
      .catch((err: AxiosError) => {
        this.errorMessage = err.response?.data.message || DEFAULT_ERROR;
        // This is likely an invalid validation amount, so clear the form
        this.amount = "";
      })
      .finally(() => {
        this.waiting = false;
      });
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form {
  width: 21.75rem;
}

h1 {
  all: unset;
  margin-bottom: 15px;
  font-family: $font-stack-wes-fy;
  font-size: 24px;
  text-align: center;
  color: $gray-800;
  line-height: 1.4;
}

.blurb strong {
  color: $gray-800;
}
</style>
