<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      :class="['business-type', 'signup-step', { '-loading': waiting }]"
      data-test="business-type"
    >
      <div class="instructions">
        <h1>Your Business</h1>
        <p class="copy">
          This onboarding process will require you to share detailed business
          information. We recommend you have the following ready before you
          begin:
        </p>
        <p class="copy">
          Business Information (EIN, physical address)
          <br />
          Ownership Information
        </p>
        <hr />
        <p><strong>You should know:</strong></p>
        <p class="copy">
          By continuing you agree that you are a controlling officer in your
          company (example: CFO, CEO, or other executive), and that you are
          authorized to enter into legally binding contracts on behalf of your
          company.
        </p>
      </div>
      <div class="questions">
        <h5 class="mb-4">Are you an:</h5>
        <b-alert
          variant="danger"
          :show="errorMessage.length > 0"
          data-test="error-business-type"
        >
          {{ errorMessage }}
        </b-alert>
        <BaseButton
          variant="light"
          block
          @click="submit(types.INDIVIDUAL)"
          data-test="business-type-btn-individual"
          >Individual (sole proprietor)</BaseButton
        >
        <BaseButton
          variant="light"
          block
          @click="submit(types.PRIVATE_COMPANY)"
          data-test="business-type-btn-private"
          >Owner/representative of a private, registered business entity (e.g.
          LLC or Inc.)</BaseButton
        >
        <BaseButton
          variant="light"
          block
          @click="submit(types.NONPROFIT)"
          data-test="business-type-btn-nonprofit"
          >Representative of a nonprofit or charity</BaseButton
        >
        <OnboardingFooterActions
          :allowChangeAccountType="true"
          @footer-action="onFooterAction"
        />
      </div>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Toast } from "@/mixins/Toast";
import { organizationStore } from "@/store";
import { BusinessType as BusinessTypeValues } from "@/types/Organization";
import OnboardingFooterActions from "@/components/OnboardingFooterActions.vue";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

const BusinessTypeTrackingNames = {
  [BusinessTypeValues.INDIVIDUAL]: "Individual",
  [BusinessTypeValues.PRIVATE_COMPANY]: "Private Company",
  [BusinessTypeValues.NONPROFIT]: "Nonprofit",
  [BusinessTypeValues.PUBLIC_COMPANY]: "Public Company",
};

@Component({
  components: {
    OnboardingFooterActions,
  },
})
export default class BusinessType extends Mixins(Toast) {
  @Prop({ default: false }) fullpage!: boolean;

  waiting = false;
  errorMessage = "";
  types = BusinessTypeValues;

  submit(businessType: BusinessTypeValues): void {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BUSINESS_TYPE)} ${BusinessTypeTrackingNames[businessType]} CTA`,
    });

    this.waiting = true;
    organizationStore.actions
      .businessType({ businessType })
      .then(() => {
        this.$emit("complete-step");
      })
      .catch((err) => {
        this.errorMessage = err.response?.data?.message || "An error occurred";
      })
      .finally(() => {
        this.waiting = false;
      });
  }

  onFooterAction(nextScreen: OnboardingSteps): void {
    this.$emit("complete-step", nextScreen);
  }
}
</script>

<style lang="scss" scoped>
.business-type {
  width: 48.5rem;
  flex-direction: row;
  padding: 0;

  @media #{$media-phone} {
    flex-direction: column;
    width: 100%;
  }
}

h1 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h1,
h5 {
  font-family: $font-stack-wes-fy;
  color: $gray-800;
}

.copy {
  color: $gray-600;
}

p:last-child {
  margin-bottom: 0;
}

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

button {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-bottom: 6px;
}

.instructions,
.questions {
  width: 50%;
  padding: 2rem;

  @media #{$media-phone} {
    width: 100%;
  }
}

.instructions {
  background-color: #f5f8fb;
}
</style>
