<template>
  <div
    class="card"
    :class="classNames"
    :style="appliedStyle"
    @click.stop="onClickCard"
    @mouseover="onMouseEnter"
    @mouseleave="onMouseLeave"
    data-test="privacy-card"
    :data-test-card-id="card.cardID"
    :data-test-card-name="card.memo"
  >
    <div class="card-content">
      <div class="branding">
        <div class="logo" v-if="logo">
          <div
            class="mcc"
            v-if="logo.type === 'mcc'"
            :style="mccStyle"
            data-test="card-mcc"
          />
          <div class="text" v-if="logo.type === 'text'" data-test="card-text">
            {{ logo.image }}
          </div>
          <div
            class="icon"
            v-else-if="logo.type === 'icon'"
            data-test="card-icon"
          >
            {{ logo.image }}
          </div>

          <SVGIcon
            v-else-if="logo.type === 'mcc'"
            :uri="logo.image"
            :style="{ fill: mccColor }"
            class="mr-2 svg-icon"
          />

          <img
            v-else
            :src="logo.image"
            :alt="logo.alt"
            loading="lazy"
            data-test="card-logo"
          />
        </div>
        <b-badge
          v-if="closed"
          class="closed"
          variant="dark"
          data-test="card-closed"
        >
          Closed
        </b-badge>
        <div
          v-else-if="thumbnail"
          class="ml-auto lighter"
          data-test="thumbnail-pan"
        >
          {{ panLastFour }}
        </div>
        <div v-else-if="paused" class="paused" data-test="card-paused">
          <span class="sr-only">Paused</span>
        </div>
      </div>
      <div v-if="preview" class="details preview">
        <div class="info">
          <div
            class="credit"
            v-if="card.promoCredit > 0"
            data-test="card-promo-credit"
          >
            {{ card.formattedPromoCredit }} Credit
          </div>
          <div
            class="name"
            data-test="card-name"
            :class="{ unused: card.unused }"
            :style="textColor"
            v-b-tooltip="{
              title: 'This card hasn\'t been used',
              disabled: !card.unused,
              delay: 300,
            }"
          >
            {{ cardName }}
          </div>
          <div :style="textColor">
            <span
              class="limit-amount"
              v-if="spendLimit.amount"
              data-test="card-limit-amount"
            >
              {{ spendLimit.amount }}
            </span>
            <span
              class="limit-description lighter"
              data-test="card-limit-description"
            >
              {{ spendLimit.description }}
            </span>
          </div>
        </div>
        <div class="lighter" data-test="card-last-four" :style="textColor">
          {{ panLastFour }}
        </div>
      </div>
      <div class="details data" v-else>
        <div
          class="align-self-start"
          :class="{ copyable: !!card.expYear }"
          v-click-to-copy.stop="card.PAN"
        >
          <span data-test="card-pan" :style="textColor">{{
            formattedPan
          }}</span>
        </div>
        <div
          class="d-flex w-100 justify-content-between mt-2 align-items-center"
        >
          <div
            :class="{ copyable: !!card.expYear }"
            v-click-to-copy.stop="cardExp"
          >
            <span class="lighter" data-test="card-exp" :style="textColor">{{
              cardExp
            }}</span>
          </div>
          <div
            :class="{ copyable: !!card.expYear }"
            v-click-to-copy.stop="card.CVV"
          >
            <span class="lighter" data-test="card-cvv" :style="textColor">
              {{ card.CVV || "***" }}
            </span>
          </div>
          <div :class="`card-network ${networkLogo}`" />
        </div>
      </div>
    </div>
    <b-dropdown
      class="card-controls"
      no-caret
      right
      lazy
      aria-label="card menu"
      variant="light"
      toggle-class="control-btn"
      data-test="card-controls"
      v-if="showControls"
      @show="tagMenuOpen = false"
    >
      <template #button-content>
        <b-icon icon="three-dots" size="sm" />
      </template>
      <b-dropdown-item
        v-for="item in cardMenu"
        :key="item.label"
        :disabled="item.disabled"
        @click.stop="item.onClick"
        :data-test="'menu-item-' + item.label.toLowerCase()"
      >
        <SVGIcon :icon="item.icon" class="mr-2" /> {{ item.label }}
      </b-dropdown-item>
    </b-dropdown>
    <CardSharing :id="`card-sharing-${uuid}-src-card`" :card="card" />
    <EditCardStyle :modalId="'edit-style' + uuid + '-src-card'" :card="card" />
    <TagMenu
      v-if="tagMenuOpen"
      @close="tagMenuOpen = !tagMenuOpen"
      :card="card"
      :card-view="true"
    />
    <EditCardNotes
      v-if="notesFlag"
      :modalId="'privacy-card-edit-notes-' + uuid"
      :card="card"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins, Emit } from "vue-property-decorator";
import { BIcon, BIconBoxArrowUpRight, BIconThreeDots } from "bootstrap-vue";
import { StyleValue } from "vue/types/jsx";
import uniqueId from "lodash/uniqueId";
import ldGet from "lodash/get";
import { Toast } from "@/mixins/Toast";
import { Confirm } from "@/mixins/Confirm";
import { Card, CardState, CardTime, CardType } from "../types/Card";
import { cardStore, subscriptionStore } from "../store";
import { parseDollarAmount } from "../util";
import SVGIcon from "./SVGIcon.vue";
import EditCardStyle from "./EditCardStyle.vue";
import EditCardNotes from "./EditCardNotes.vue";
import TagMenu from "./TagMenu.vue";
import CardSharing from "./modals/CardSharing.vue";

const baseImageBucket = "https://s3.amazonaws.com/privacy-web/images/";
const CARD_IMAGES = baseImageBucket + "cards/";
const MCC_ICONS = baseImageBucket + "streamline/";

function getPseudoRandomColor(mccInput: number | string, alpha?: string) {
  const mcc = Number(mccInput);

  let x = Math.sin(mcc) * 10000;
  x -= Math.floor(x);

  const hue = Math.floor(x * 360);
  const saturation = 100;
  const lightness = Math.floor(x * 25) + 45; // 35-70%

  return alpha
    ? `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`
    : `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

interface DropdownMenuItem {
  icon: string;
  label: string;
  disabled?: boolean;
  onClick(): void;
}

@Component({
  components: {
    CardSharing,
    EditCardStyle,
    EditCardNotes,
    SVGIcon,
    TagMenu,
    BIcon,
    BIconBoxArrowUpRight,
    BIconThreeDots,
  },
})
export default class PrivacyCard extends Mixins(Toast, Confirm) {
  @Prop({ default: () => ({}) }) cardProp!: Card;
  @Prop({ default: false }) preview!: boolean;
  @Prop({ default: false }) thumbnail!: boolean;

  uuid = uniqueId();
  tagMenuOpen = false;
  focused = false;
  get notesFlag() {
    return !!this.subscription?.features.cardNotes;
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  get card(): Card {
    return cardStore.getters.getCard(this.cardProp.cardID) || this.cardProp;
  }

  get classNames(): Record<string, boolean | undefined> {
    const { type, meta = {}, cardID, created } = this.card;

    return {
      dark: type !== CardType.PHYSICAL && this.style.bgColor,
      expiring: meta?.isExpiring,
      preview: this.preview,
      sample: !cardID && !created,
    };
  }

  get cardName(): string {
    return this.card.memo;
  }

  get spendLimit(): Record<string, string> {
    const {
      spendLimit,
      spendLimitDuration,
      spentThisMonthRolling = 0,
      spentThisYearRolling = 0,
      spentTotal = 0,
    } = this.card;

    if (!spendLimit) {
      return {
        amount: "",
        description: "No Limit",
      };
    }
    const displayLimit = parseDollarAmount(spendLimit, 0);
    let amount = displayLimit;
    let description = " / " + displayLimit;

    if (spendLimitDuration === CardTime.TRANSACTION) {
      description = "per transaction";
    } else if (spendLimitDuration === CardTime.MONTHLY) {
      amount = parseDollarAmount(spentThisMonthRolling);
    } else if (spendLimitDuration === CardTime.ANNUALLY) {
      amount = parseDollarAmount(spentThisYearRolling);
    } else {
      amount = parseDollarAmount(spentTotal);
    }

    return {
      amount,
      description,
    };
  }

  get panLastFour(): string {
    const { PAN: cardPan } = this.card;

    if (cardPan) {
      return cardPan.substring(cardPan.length - 4);
    }

    return "****";
  }

  get style(): Record<string, any> {
    const { type, style = {}, merchantCategory } = this.card;

    if (type === CardType.DIGITAL_WALLET) {
      return {
        filename: "/assets/images/icons/digital-wallet.svg",
        alt: "Digital Wallet",
        bgColor: "232320",
      };
    }

    if (type === CardType.SINGLE_USE) {
      return {
        filename: "/assets/images/icons/card-icon-burner.svg",
        alt: "Single Use",
        bgColor: "FF2D36",
      };
    }

    if (type === CardType.UNLOCKED && !merchantCategory) {
      return {
        bgColor: "39f",
      };
    }

    return style || {};
  }

  get appliedStyle(): StyleValue | undefined {
    const style = this.style;

    const styles: StyleValue = {};
    let applyStyle = false;

    if (style.bgColor) {
      applyStyle = true;
      styles["background-color"] = "#" + style.bgColor;
      styles["box-shadow"] = "0 10px 20px #" + style.bgColor + "4d";
    }

    if (style.bgImage) {
      styles["background-image"] = "url(" + this.bgImage + ")";
    }

    return applyStyle ? styles : undefined;
  }

  get bgImage(): string {
    const style = this.style;
    const { type } = this.card;

    let path = this.style.bgImage;

    if (type !== CardType.DIGITAL_WALLET) {
      path = CARD_IMAGES + path;

      if (style.lastModified) {
        path += "?lastModified" + style.lastModified;
      }
    }

    return path;
  }

  get isCategoryCard(): boolean {
    return this.card.type === CardType.UNLOCKED && !!this.card.merchantCategory;
  }

  get logo(): Record<string, string> | null {
    const {
      filename,
      lastModified,
      icon,
      alt,
      friendlyNames,
      strippedHostname,
    } = this.style;
    const { type, mccIconUrl } = this.card;

    let image = "";
    let imageType = "image";
    const altText =
      alt ||
      (friendlyNames ? friendlyNames[0] : undefined) ||
      strippedHostname ||
      "";

    if (icon) {
      image = icon;
      imageType = "icon";
    } else if (
      filename &&
      (type === CardType.MERCHANT_LOCKED || this.isCategoryCard)
    ) {
      // Merchant locked and category cards can have filename styles
      image = CARD_IMAGES + filename;

      if (lastModified) {
        image += "?lastModified" + lastModified;
      }
    } else if (filename) {
      image = filename;
    } else if (type === CardType.UNLOCKED && !this.isCategoryCard) {
      image = "Unlocked";
      imageType = "text";
    } else if (mccIconUrl && !icon) {
      image = MCC_ICONS + mccIconUrl;
      imageType = "mcc";
    }

    if (!image) {
      return null;
    }

    return {
      type: imageType,
      image,
      alt: altText,
    };
  }

  get mccStyle(): StyleValue | undefined {
    const { icon, filename, bgColor } = this.style;
    const { mccIconUrl, mcc } = this.card;

    if (!mccIconUrl || icon || filename) {
      return undefined;
    }

    let styles: StyleValue = {};

    if (!bgColor) {
      styles = { "background-color": getPseudoRandomColor(mcc, "10%") };
    }

    return styles;
  }

  get mccColor(): string | undefined {
    const { mcc } = this.card;
    return getPseudoRandomColor(mcc, "100%");
  }

  get showControls(): boolean {
    return this.card.state !== CardState.CLOSED && this.preview && this.focused;
  }

  get textColor(): StyleValue | undefined {
    const { type } = this.card;

    if (type === CardType.DIGITAL_WALLET) {
      return { color: "#ffffff" };
    }

    const hexCardBackgroundColor = this.card.style?.bgColor?.replace("#", "");

    if (!hexCardBackgroundColor) {
      return {
        color: "#000000",
      };
    }

    // Split the hex code into three parts: red, green, and blue
    const r = parseInt(hexCardBackgroundColor.substring(0, 2), 16);
    const g = parseInt(hexCardBackgroundColor.substring(2, 4), 16);
    const b = parseInt(hexCardBackgroundColor.substring(4, 6), 16);

    // Use the formula for luminance to determine the best color for readability
    const isLightBackground = r * 0.299 + g * 0.587 + b * 0.114 < 186;

    return { color: isLightBackground ? "#ffffff" : "#000000" };
  }

  get cardMenu(): DropdownMenuItem[] {
    const menu: DropdownMenuItem[] = [];
    const card = this.card;

    if (card.state === CardState.PAUSED) {
      menu.push({
        icon: "play",
        label: "Unpause",
        disabled: this.disableResume,
        onClick: this.unpauseCard,
      });
    }

    if (card.state === CardState.OPEN) {
      menu.push({
        icon: "pause",
        label: "Pause",
        onClick: this.pauseCard,
      });
    }

    if (
      card.type === CardType.MERCHANT_LOCKED ||
      (card.reloadable && card.type !== CardType.DIGITAL_WALLET)
    ) {
      menu.push({
        icon: "paint-roller",
        label: "Customize",
        onClick: this.onClickCustomize,
      });
    }

    if (card.cardID) {
      if (this.notesFlag) {
        menu.push({
          icon: "speech-bubble",
          label: "Notes",
          onClick: this.showNotesModal,
        });
      }

      menu.push({
        icon: "tag",
        label: "Tag",
        onClick: this.onClickTag,
      });

      if (card.type !== CardType.DIGITAL_WALLET) {
        menu.push({
          icon: "box-arrow-up",
          label: "Share",
          onClick: this.onClickShare,
        });
      }

      menu.push({
        icon: "trash",
        label: "Close",
        onClick: this.confirmCloseCard,
      });
    }

    return menu;
  }

  get paused(): boolean {
    return this.card.state === CardState.PAUSED;
  }

  get closed(): boolean {
    return this.card.state === CardState.CLOSED;
  }

  get formattedPan(): string {
    const pan = this.card.PAN || "****************";
    return pan.replace(/(.{4})/g, "$1 ").trim();
  }

  get cardExp(): string {
    const { expMonth, expYear } = this.card;
    return expMonth && expYear
      ? `${expMonth}/${expYear.substring(2)}`
      : "**/**";
  }

  get networkLogo(): string | undefined {
    // NOTE: Ideally PCI would return a network name string, and this checks for that first.
    // That currently isn't implemented, so we're falling back to simple BIN pattern matching.

    let network: string | undefined;

    let useDarkNetworkLogo = false;

    if (this.card.type === CardType.MERCHANT_LOCKED) {
      const cardStyle = this.card.style;
      if (!cardStyle || !cardStyle.bgColor || cardStyle.bgColor === "ffffff") {
        useDarkNetworkLogo = true;
      }
      if (this.card.mccIconUrl && !this.style.bgColor && !this.style.filename) {
        // only use dark logo if there is an mcc but no bg color and no filename
        useDarkNetworkLogo = true;
      }
    }

    if (this.card.network) {
      network = this.card.network;
    } else {
      const networkBinPatterns = [
        {
          name: useDarkNetworkLogo ? "visa-dark" : "visa",
          pattern: /^4/,
        },
        {
          name: "mastercard",
          pattern:
            /^((222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)|(5[1-5]))/, // 2221-2720, 51-55
        },
        {
          name: "jcb",
          pattern: /^(352[89]|35[3-8][0-9])/, // 3528-3589
        },
      ];

      const pattern = networkBinPatterns.find((network) => {
        return network.pattern.test(this.card.PAN);
      });

      network = pattern && pattern.name;
    }

    return network;
  }

  get hostname(): string {
    const { hostname, meta = {} } = this.card;

    return hostname || meta?.hostname || "";
  }

  get isPaidFeatureCard(): boolean {
    return this.isCategoryCard;
  }

  get disableResume(): boolean {
    return (
      this.card?.state === CardState.PAUSED &&
      this.isPaidFeatureCard &&
      !this.subscription?.features.categoryCards
    );
  }

  onMouseEnter(): void {
    this.focused = true;
  }

  onMouseLeave(): void {
    this.focused = false;
    this.tagMenuOpen = false;
  }

  onClickCustomize(): void {
    this.$bvModal.show("edit-style" + this.uuid + "-src-card");
  }

  onClickTag(): void {
    this.tagMenuOpen = true;
  }

  onClickShare(): void {
    this.$bvModal.show(`card-sharing-${this.uuid}-src-card`);
  }

  confirmCloseCard(): void {
    this.confirm("Are you sure? This can't be undone.", {
      title: "Close this card?",
    }).then((resp) => {
      if (resp) {
        this.performAction(
          "close",
          "Card Closed",
          "Failed to close card",
          "card-closed"
        );
      }
    });
  }

  showNotesModal() {
    this.$bvModal.show("privacy-card-edit-notes-" + this.uuid);
  }

  unpauseCard(): void {
    cardStore.actions
      .unpause(ldGet<any, string>(this, "card.cardUuid"))
      .then(() => {
        this.$emit("card-unpaused");
        this.successToast("Card Resumed");
      })
      .catch((err) => {
        this.errorToast(
          ldGet<any, any, string>(
            err,
            "response.data.message",
            "Failed to unpause card"
          )
        );
      });
  }

  pauseCard(): void {
    cardStore.actions
      .pause(ldGet<any, string>(this, "card.cardUuid"))
      .then(() => {
        this.$emit("card-paused");
        this.successToast("Card Paused");
      })
      .catch(({ data }) => {
        this.errorToast(
          ldGet<any, any, string>(data, "message", "Failed to pause card")
        );
      });
  }

  performAction(
    action: string,
    success: string,
    error: string,
    eventToEmit?: any
  ): void {
    const cardId = this.card.cardID;
    // @ts-ignore
    const storeAction = cardStore.actions[action](cardId) as Promise<void>;

    storeAction
      .then(() => {
        this.successToast(success);
        if (eventToEmit) {
          this.$emit(eventToEmit);
        }
      })
      .catch(({ data }) => {
        this.errorToast(ldGet<any, string, string>(data, "message", error));
      });
  }

  @Emit("click-card")
  onClickCard(): Card {
    return this.cardProp;
  }
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  background: #fff center no-repeat;
  border-radius: 16px;
  box-shadow: 0 10px 20px $color-shadow;
  color: $card-grey-darker;
  font-family: $font-stack-ocr;
  font-size: $font-size-base;
  height: 150px;
  width: 240px;
  padding: 20px;
  transition: transform 0.1s ease-in-out;
  user-select: none;
  z-index: 0;

  &.preview {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      transform: scale(1.025);
      z-index: 1;
    }
  }

  &.dark {
    color: #fff;
  }
}

.card-content {
  display: flex;
  flex-flow: column;
  white-space: nowrap;
  flex-grow: 1;
}

.expiring::before {
  content: "";
  width: 34px;
  height: 34px;
  position: absolute;
  left: 15px;
  top: -17px;
  background-image: url("/assets/images/home/charge-terms/expiring-clock.png");
  background-size: contain;
}

.branding {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  position: relative;
  flex-grow: 1;

  .icon {
    font-size: 36px;
  }

  img {
    max-width: 120px;
    max-height: 30px;
    object-fit: contain;
    position: relative;

    .burner & {
      height: 38px;
      width: 34px;
    }
  }

  .mcc {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    border-radius: 10px;
    margin-left: -10px;

    + img {
      height: 30px;
      width: 30px;
    }
  }

  .text {
    font-family: $font-stack-wes-fy;
    font-size: 24px;
  }
}

.details {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data {
  flex-flow: column;
}

.info {
  font-family: $font-stack-lato;
  align-self: flex-end;
  overflow: hidden;
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 5px;
}

.paused {
  height: 26px;
  width: 28px;
  background: $card-grey-darker url(/assets/images/icons/pause-20-white.svg)
    no-repeat center;
  border-radius: $border-radius;
  margin-left: auto;

  .dark & {
    background-image: url(/assets/images/icons/pause-20.svg);
    background-color: $gray-200;
  }
}

.closed {
  padding: 8px 10px;
  margin-left: auto;
  text-transform: uppercase;
  font-family: $font-stack-lato;
  font-weight: 900;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 12px;
}

.lighter {
  opacity: 0.6;
}

.unused::before {
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: $blue;
  box-shadow:
    inset 0 0 0 1px $color-shadow-black-fainter,
    $box-shadow-default-faint;
  border: 1px solid $white;
  border-radius: 50%;
  margin-right: 6px;
}

.card-controls {
  position: absolute;
  top: -12px;
  right: -9px;
  font-family: $font-stack-lato;

  ::v-deep {
    .control-btn {
      height: 31px;
      min-width: 29px;
      font-size: 18px;
      background-color: #fff !important;
      box-shadow: $box-shadow-hairline, $box-shadow-default-fainter;
      padding: 0 6px;

      &:hover .b-icon {
        fill: $gray-800;
      }
    }

    .b-icon {
      fill: $gray-600;
    }

    .dropdown-item {
      display: flex;
      align-items: center;

      .svg-icon * {
        fill: $gray-600;
      }

      &:hover .svg-icon * {
        fill: $gray-800;
      }
    }
  }
}

::v-deep .tag-menu {
  bottom: unset;
  top: 24px;
  right: -8px;
}

.svg-icon {
  width: 30px;
  height: 30px;
  display: block;
}

.card-network {
  height: 22px;
  width: 46px;
  background: no-repeat right center;
  background-size: contain;

  &.visa {
    background-image: url(/assets/images/privacy-card/networks/visa.svg);
  }

  &.visa-dark {
    background-image: url(/assets/images/privacy-card/networks/visa-dark.svg);
  }

  &.mastercard {
    background-image: url(/assets/images/privacy-card/networks/mastercard.svg);
  }

  &.jcb {
    background-image: url(/assets/images/privacy-card/networks/jcb.svg);
  }
}

.copyable {
  position: relative;
  cursor: pointer;

  span {
    position: relative;
    z-index: 2;
  }

  &::before {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    content: "";
    position: absolute;
    top: -5px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 8px);
    z-index: 1;
    border-radius: 6px;
    background: fade-out($card-grey-darker, 0.9);

    .dark & {
      background: fade-out($black, 0.75);
    }
  }

  &:hover::before {
    opacity: 1;
  }
}
</style>
