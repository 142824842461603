<template>
  <div>
    <div class="section-heading">
      <h2>Funding Source</h2>
      <BaseButton
        variant="primary"
        v-if="shouldShowAddFunding"
        @click="onAddFundingSource"
        data-test="heading-add-funding"
      >
        Add Funding Source
      </BaseButton>
    </div>
    <div class="account-funding section -funding">
      <div v-if="shouldShowZeroState" class="zero-state">
        <p class="description">
          You'll need to add your {{ accountFundingType }} to start making
          purchases.
        </p>
        <div>
          <BaseButton
            class="add-funding"
            variant="primary"
            data-test="add-funding-button"
            size="lg"
            @click="onAddFundingSource"
          >
            <b-icon icon="plus" />
            <strong>Add your {{ accountFundingType }}</strong>
          </BaseButton>
        </div>
        <div class="description" v-if="shouldShowPendingTransactionWarning">
          <p>
            <strong>Note:</strong> Any pending transactions you have with
            Privacy will be charged to the last funding source you had
            connected.
          </p>
          <p>
            If you add a new funding source, any transactions that haven't
            cleared will be charged to the new funding source.
          </p>
        </div>
      </div>
      <div v-else>
        <div
          class="descriptor-info"
          v-if="showNewDiscreetMerchants && hasActiveFundingSource"
        >
          <div>Transactions will appear on your bank statement as:</div>
          <div class="sample-descriptor">
            <span class="descriptor-label">{{ sampleDescriptor }}</span>
            <div
              class="private-spend-badge"
              v-if="!fundingCardBatchStatus && !includeMerchantName"
            >
              <SVGIcon icon="eye-slash" />
              Private Spend Mode
            </div>
          </div>
          <div class="merchant-toggle" v-if="!fundingCardBatchStatus">
            <b-form-checkbox
              switch
              size="lg"
              v-model="includeMerchantName"
              @change="toggleIncludeMerchant"
            ></b-form-checkbox>
            Include merchant information
          </div>
        </div>
        <div
          v-for="source in fundingSourceList"
          :key="source.uuid"
          :class="{ 'pb-2': hasExpiredToken(source) }"
        >
          <div
            class="funding-source-container"
            data-test="funding-source-container"
          >
            <div class="funding-item">
              <div class="source">
                <FundingSource :source="source" />
              </div>
              <BaseButton
                v-if="source.state === 'PENDING'"
                variant="primary"
                size="sm"
                data-test="confirm-funding-source"
                @click="confirmFundingSource(source)"
              >
                Confirm
              </BaseButton>
              <b-dropdown
                no-caret
                data-test="funding-source-dropdown"
                aria-label="funding source menu"
              >
                <template #button-content>
                  <b-icon icon="three-dots" size="sm" />
                </template>
                <b-dropdown-item
                  v-for="dropdownItem in source.dropdownMenu"
                  :key="dropdownItem.label"
                >
                  <div @click="dropdownItem.onClick">
                    {{ dropdownItem.label }}
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="funding-info">
              {{ fundingInfoContent }}
              <b-link
                v-if="fundingInfoLink?.href"
                :href="fundingInfoLink?.href"
                :aria-label="fundingInfoLink?.aria"
                target="_blank"
              >
                {{ fundingInfoLink?.text }}
              </b-link>
            </div>
          </div>
          <b-alert
            :show="showRefreshOption && hasExpiredToken(source)"
            class="mb-3 text-strong"
            variant="warning"
          >
            This bank connection is out-of-date, please refresh it.
          </b-alert>
        </div>
      </div>
    </div>
    <ChangeDefaultFunding
      :source="modalSource"
      @refresh-funding-sources="refreshFundingSources"
    />
    <BaseModal id="add-funds-prepaid">
      <AddFunds @refresh-funding-sources="refreshFundingSources" />
    </BaseModal>
    <RenameFundingSource
      :source="modalSource"
      @refresh-funding-sources="refreshFundingSources"
    />
    <CashOut
      :user="user"
      :source="modalSource"
      @refresh-funding-sources="refreshFundingSources"
    />
    <BaseModal id="confirm-bank-modal" static lazy>
      <ConfirmBank @complete-step="onConfirmComplete" />
    </BaseModal>
    <BaseModal id="confirm-card-modal" static lazy>
      <ConfirmCard @complete-step="onConfirmComplete" />
    </BaseModal>
    <BaseModal id="onboarding-modal" class="onboarding-modal" static lazy>
      <OnboardingContainer
        @finished-onboarding="onFinishedOnboarding"
        :isAddingFunding="!justConfirmedFunding"
      />
    </BaseModal>
    <BaseModal id="update-bank-modal" static lazy>
      <UpdateBankLink :bank-account="modalSource" @close="onUpdateBankLink" />
    </BaseModal>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { BIcon, BIconPlus, BIconThreeDots } from "bootstrap-vue";
import { TransactionList } from "@/types/Transaction";
import { Toast } from "@/mixins/Toast";
import { Confirm } from "@/mixins/Confirm";
import {
  bankStore,
  featureStore,
  fundingCardStore,
  transactionStore,
  userStore,
} from "@/store";
import FundingSource from "@/components/FundingSource.vue";
import ChangeDefaultFunding from "@/components/modals/ChangeDefaultFunding.vue";
import AddFunds from "@/components/modals/AddFunds.vue";
import RenameFundingSource from "@/components/modals/RenameFundingSource.vue";
import CashOut from "@/components/modals/CashOut.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import FundingType from "@/views/funding/FundingType.vue";
import AddFunding from "@/views/funding/AddFunding.vue";
import ConfirmBank from "@/views/funding/ConfirmBank.vue";
import ConfirmCard from "@/views/funding/ConfirmCard.vue";
import OnboardingContainer from "@/views/OnboardingContainer.vue";
import UpdateBankLink from "@/views/funding/UpdateBankLink.vue";
import {
  FundingBankWithDropdown,
  FundingCardWithDropdown,
  FundingSourceType,
} from "@/types/Funding";
import { sourceIsBank, sourceIsCard } from "@/lib/typeguards";
import { OnboardingSteps } from "@/types/Onboarding";
import { LDFlagValue } from "@launchdarkly/node-server-sdk";
import { FeatureFlags } from "@/types/LaunchDarkly";

@Component({
  components: {
    FundingSource,
    ChangeDefaultFunding,
    AddFunds,
    RenameFundingSource,
    CashOut,
    FundingType,
    AddFunding,
    OnboardingContainer,
    ConfirmBank,
    ConfirmCard,
    UpdateBankLink,
    SVGIcon,
    BIcon,
    BIconPlus,
    BIconThreeDots,
  },
})
export default class Funding extends Mixins(Toast, Confirm) {
  @Prop({ default: false }) showNewDiscreetMerchants!: boolean;

  loading = true;
  fundingSourceList: Array<FundingBankWithDropdown | FundingCardWithDropdown> =
    [];
  modalSource?: FundingSourceType | null = null;
  transactions: TransactionList = { all: [], declines: [] };
  justConfirmedFunding = false;
  showRefreshOption = false;
  includeMerchantName = true;

  get user() {
    return userStore.getters.currentUser!;
  }

  get accountFundingType() {
    return this.user.isPrepaid ? "card" : "funding source";
  }

  get shouldShowZeroState() {
    return (this.fundingSourceList || []).length === 0;
  }

  get shouldShowAddFunding() {
    return !(
      this.shouldShowZeroState ||
      (this.fundingSourceList.length > 0 && this.user.accountType === "PREPAID")
    );
  }

  get shouldShowPendingTransactionWarning() {
    return (
      this.fundingSourceList.length === 0 && this.transactions.all.length > 0
    );
  }

  get hasActiveFundingSource() {
    return this.fundingSourceList.some((source) => source.state === "ENABLED");
  }

  get sampleDescriptor() {
    if (this.fundingCardBatchStatus) {
      return "PWP*Privacy {Transaction Date}";
    }

    if (!this.includeMerchantName) {
      return "PWP*Privacy.com";
    }

    return "PWP*{Merchant Information}";
  }

  get isCardFunded() {
    return this.fundingSourceList.some((source) => sourceIsCard(source));
  }

  get fundingInfoContent() {
    if (this.fundingCardBatchStatus) {
      return " All transactions made using a Privacy Card over a 24-hour period will be collected from your debit card as an aggregated amount.";
    } else if (this.isCardFunded) {
      return "All transactions made using a Privacy Card will be settled individually from your debit card.";
    }
    return "All transactions made using a Privacy Card will be settled individually from your bank account within 1-3 business days.";
  }

  get fundingInfoLink() {
    if (this.fundingCardBatchStatus) {
      return {
        href: "https://support.privacy.com/hc/en-us/articles/25651041228439-What-is-batch-debit-funding",
        aria: "What is batch debit funding?",
        text: "Learn more about how we settle transactions.",
      };
    }
    return undefined;
  }

  get fundingCardBatchStatus() {
    return !!userStore.getters.accountInfo?.fundingCardBatchStatus;
  }

  created() {
    transactionStore.actions
      .fetchTransactions({})
      .then((transactionList: TransactionList) => {
        this.transactions = transactionList;
        this.loading = false;
      });

    featureStore.actions
      .fetchFlag({
        id: FeatureFlags.REFRESH_BANK_TOKEN,
      })
      .then((flag: LDFlagValue) => {
        this.showRefreshOption = flag;
        // Regenerate the list once flag has loaded, but don't let it block
        this.fundingSourceList = this.generateFundingSourceList();
      });

    this.fundingSourceList = this.generateFundingSourceList();
    this.fetchAccountInfo();
  }

  fetchAccountInfo() {
    userStore.actions.getAccountInfo().then((accountInfo) => {
      this.includeMerchantName =
        !accountInfo.keyValuePair["user:hide_merchant_descriptor"];
    });
  }

  onAddFundingSource() {
    this.justConfirmedFunding = false;
    this.$bvModal.show("onboarding-modal");
  }

  onFinishedOnboarding() {
    this.$bvModal.hide("onboarding-modal");
    userStore.actions.getCurrentUser().then(() => {
      this.fundingSourceList = this.generateFundingSourceList();
    });
    this.fetchAccountInfo();
  }

  hasExpiredToken(source: FundingSourceType) {
    return sourceIsBank(source) && source.tokenState === "ERROR";
  }

  onUpdateBankLink(source: any) {
    this.$bvModal.hide("update-bank-modal");
    if (source) {
      source.tokenState = "ACTIVE";
      source.dropdownMenu = source.dropdownMenu.filter(
        (item: any) => item.label !== "Refresh"
      );
    }
  }

  confirmFundingSource(source: FundingSourceType) {
    if (sourceIsBank(source)) {
      bankStore.mutations.setConfirmingBank(source);
      this.$bvModal.show("confirm-bank-modal");
      return;
    }

    this.$bvModal.show("confirm-card-modal");
  }

  onConfirmComplete() {
    this.successToast("Bank confirmed");
    this.$bvModal.hide("confirm-card-modal");
    this.$bvModal.hide("confirm-bank-modal");
    this.justConfirmedFunding = true;
    const nextStep = userStore.getters.nextOnboardingStep();
    if (nextStep !== OnboardingSteps.COMPLETE) {
      this.$bvModal.show("onboarding-modal");
    } else {
      this.fetchAccountInfo();
    }
  }

  generateFundingSourceList() {
    const { bankAccountList = [], fundingCardList = [] } = this.user;
    const multipleAccounts =
      bankAccountList.length + fundingCardList.length > 1;

    const fundingSourceList = [...bankAccountList, ...fundingCardList].map(
      (source) => {
        const dropdownMenu: any[] = [];

        if (!this.user.isPrepaid && multipleAccounts && !source.default) {
          dropdownMenu.push({
            label: "Make Default",
            onClick: () =>
              this.showSourceModal({
                source,
                name: "change-default-funding",
              }),
            class: "js-make-default",
          });
        }

        if (sourceIsBank(source)) {
          dropdownMenu.push({
            label: "Rename",
            onClick: () =>
              this.showSourceModal({
                source,
                name: "rename-funding-source",
              }),
            class: "js-rename",
          });
        }

        if (this.showRefreshOption && this.hasExpiredToken(source)) {
          dropdownMenu.push({
            label: "Refresh",
            onClick: () => {
              this.showSourceModal({
                source,
                name: "update-bank-modal",
              });
            },
          });
        }

        if (source.bin && this.user.isPrepaid && source.state === "ENABLED") {
          dropdownMenu.push({
            label: "Add Funds",
            onClick: () => this.$bvModal.show("add-funds-prepaid"),
            class: "js-addfunds",
          });

          if (userStore.getters.prepaidBalanceAmount > 0) {
            dropdownMenu.push({
              label: "Cash out",
              onClick: () => this.$bvModal.show("cash-out"),
              class: "js-cashout",
            });
          }
        }

        dropdownMenu.push({
          label: "Delete",
          onClick: () => this.deleteFundingSource(source),
          class: "js-delete",
        });

        source.dropdownMenu = dropdownMenu;
        return source;
      }
    );

    return fundingSourceList;
  }

  refreshFundingSources() {
    userStore.actions.getCurrentUser().then(() => {
      this.fundingSourceList = this.generateFundingSourceList();
    });

    this.fetchAccountInfo();
  }

  showSourceModal({
    source,
    name,
  }: {
    source: FundingSourceType;
    name: string;
  }) {
    this.modalSource = source;
    this.$bvModal.show(name);
  }

  deleteFundingSource(source: FundingSourceType) {
    this.confirm("This action can't be undone", {
      title: "Are you sure?",
      size: "sm",
      okTitle: "Delete",
      okVariant: "danger",
    })
      .then((confirmed: boolean) => {
        let promise;

        if (confirmed && sourceIsBank(source)) {
          promise = bankStore.actions.delete({
            bankAccountID: source.bankAccountID,
          });
        } else if (confirmed && sourceIsCard(source)) {
          promise = fundingCardStore.actions.delete({
            uuid: source.uuid || "",
          });
        }

        if (promise) {
          promise.then(() => {
            this.successToast("Funding source deleted");
            this.refreshFundingSources();
          });
        }
      })
      .catch(() => {
        this.errorToast("Sorry, we couldn't delete your funding source");
      });
  }

  toggleIncludeMerchant(includeMerchant: boolean) {
    userStore.actions
      .setHideMerchantDescriptor(!includeMerchant)
      .then(() => {
        this.successToast("Saved merchant display setting");
      })
      .catch((err) => {
        this.includeMerchantName = !includeMerchant;
        this.errorToast(
          err.response?.data?.message || "Error saving merchant display setting"
        );
      });
  }
}
</script>

<style>
.modal-body {
  padding: 10px;
}
</style>
<style lang="scss" scoped>
.section-heading {
  display: flex;
  align-items: center;
}

.account-funding.section {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.account-funding .funding-source-container {
  display: flex;
  flex-direction: column;
  margin: 0 -40px;
  padding: 24px 40px;
}

.account-funding .funding-source-container .funding-item {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.account-funding .funding-source-container .funding-info {
  color: #323242;
  margin-top: 24px;
  font-size: 12px;
}

.zero-state {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
}

.description {
  margin-bottom: 25px;
}

div.description {
  margin-top: 25px;
  margin-bottom: 0;
}

.description + .funding-source-container,
.funding-source-container + .funding-source-container {
  border-top: 2px solid $white;
}

.funding-source-container > .source {
  margin-right: auto;
  min-width: 0;
}

// override a bunch of bootstrap dropdown styles
.funding-source-container ::v-deep .dropdown.b-dropdown {
  width: unset;
  height: unset;
  display: flex;
  margin: 0;
  padding: 0;
  background-color: transparent;
  box-shadow: unset;

  & button.btn {
    margin-left: 10px;
    color: $gray-800;
    background: transparent;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: none;
    padding: 0;

    &:hover {
      background: $gray-200;
    }
  }
}

.descriptor-info {
  border-bottom: 1px solid $white;
  color: $gray-800;
  padding: 24px 0;
}

.sample-descriptor {
  align-items: center;
  background-color: $white;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  justify-content: space-between;
  margin-top: 12px;
  padding: 12px 16px;
}

.descriptor-label {
  line-height: 26px;
}

.merchant-toggle {
  display: flex;
  flex-direction: row;
  padding: 24px 0 0;

  ::v-deep .custom-switch {
    .custom-control-label::before {
      background-color: $accent-50;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $accent-default;
    }

    .custom-control-label::after,
    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: $white;
    }
  }
}

.private-spend-badge {
  align-items: center;
  background-color: $accent-10;
  border-radius: 4px;
  color: $accent-default;
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  padding: 4px 8px;
}

.svg-icon {
  height: 12px;
  margin-right: 6px;
  width: 12px;

  ::v-deep path {
    fill: $accent-default;
  }
}

@media #{$media-phone} {
  .funding-source-container {
    flex-wrap: wrap;
    padding: 26px 40px;
    height: auto;
  }

  .funding-source-container .source {
    flex-basis: calc(100% - 40px);
    margin-right: 0;
  }

  .funding-source-container .menu {
    flex-basis: 20px;
    margin-left: 0;
  }

  .funding-source-container > .confirm-button {
    flex-basis: 100%;
    order: 3;
    margin-top: 20px;
  }
}

::v-deep .onboarding-modal .modal-dialog {
  max-width: fit-content;
}

::v-deep .onboarding-modal {
  .modal-body {
    padding: 0;
  }
}
</style>
